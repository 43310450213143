import SystemHandler from "actions/SystemHandler";
import { EmptySpace, Form, Input, SelectDropdown } from "common/form";
import { IAddEntity, IPartnerBusinessInfo } from "common/interfaces";
import React, { useEffect, useState } from "react";
import { statesArray } from "utils/constants";
import { AddressValidation, BusinessValidation, Schema } from "utils/validation/additionalValidation";

const BusinessInformationForm: React.FC<IAddEntity> = ({ onNext, initialValues }) => {

  const [partnerTypesOptions, setPartnerTypesOptions] = useState([]);
  const [numberMerchantsOptions, setNumberMerchantsOptions] = useState([]);

  useEffect(() => {
    Promise.all([
      SystemHandler.getPartnerTypes(),
      SystemHandler.getNumberOfMerchants()
    ]).then(responses => {
      setPartnerTypesOptions(responses[0]);
      setNumberMerchantsOptions(responses[1])
    });
  }, []);

  return <Form
    id="addPartnerPopup_businessInformation"
    title="Add partner • Business information"
    submitBtnText="Next"
    onFormSubmit={(data: IPartnerBusinessInfo) => { onNext(data); }}
    validationSchema={Schema({
      ...BusinessValidation,
      ...AddressValidation
    })}
    values={initialValues}
    buttonPosition="bottom right"
    inputFields={[
      [
        <Input name="name" id="addPartnerPopup_name" label="Business name*" />,
        <Input name="website" id="addPartnerPopup_website" label="Website*" />,

      ],
      [
        <Input name="external_id" id="addPartnerPopup_external_id" label="External ID" />,
        <EmptySpace />
      ],
      [
        <Input name="address1" id="addPartnerPopup_address1" label="Business main address*" />,
        <Input name="city" id="addPartnerPopup_city" label="Business city*" />,
      ],
      [
        <SelectDropdown
          id="addPartnerPopup_state"
          label="State*"
          name="state"
          placeholder="Select"
          selectOptions={statesArray} />,
        <Input type="text" name="postal_code" id="addPartnerPopup_postal_code" label="Postal code*" maxLength={5} />
      ],
      [
        <SelectDropdown selectOptions={numberMerchantsOptions} name="merchant_number" id="addPartnerPopup_merchant_number" label="No. of merchants in network" />,
        <SelectDropdown selectOptions={partnerTypesOptions} name="type" id="addPartnerPopup_industry_specialty" label="Specialty" />
      ]
    ]}
    isInsidePopup
    useSmallerTitle
  />;
}

export default BusinessInformationForm;
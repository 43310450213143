// used only on view merchant of whitelabel or partner - first tab

import React, { useEffect, useState } from "react";
import _ from "lodash";
import TopbarTabs from "common/parts/TopbarTabs";
import { MerchantTabType } from "common/types";
import MerchantAccountTab from "./MerchantAccountTab";
import MerchantUsersLocationsTab from "./MerchantUsersLocationsTab";
import MerchantExperienceSettingsTab from "./MerchantExperienceSettingsTab";
import { useNavigate } from "react-router-dom";
import useMerchant from "hooks/useMerchant";

interface IMerchantAccountInformation2 {
  view: MerchantTabType;
}

const MerchantAccountInformation2: React.FC<IMerchantAccountInformation2> = ({ view }) => {
  const tabs: MerchantTabType[] = ["accountInformation", "usersLocations", "experienceSettings"];

  const [activeTab, setActiveTab] = useState(view ? tabs.indexOf(view) : 0);
  const navigate = useNavigate();
  const merchant = useMerchant();


  const handleTabClick = (index) => {
    setActiveTab(index);
  }

  useEffect(() => {
    if (merchant?.slug) {
      navigate(`/viewMerchant/${merchant?.slug}/${getMerchantInfoCardVariant()}`);
    }
  }, [activeTab]);

  const getMerchantInfoCardVariant = (): MerchantTabType => {
    return tabs[activeTab];
  }

  return <>

    <TopbarTabs
      items={[{ label: "Merchant account" }, { label: "Users & locations" }, { label: "Application settings" }]}
      activeTab={activeTab}
      onClick={handleTabClick}
    />

    {getMerchantInfoCardVariant() === "accountInformation" && <MerchantAccountTab variant={getMerchantInfoCardVariant()} />}
    {getMerchantInfoCardVariant() === "usersLocations" && <MerchantUsersLocationsTab variant={getMerchantInfoCardVariant()} />}
    {getMerchantInfoCardVariant() === "experienceSettings" && <MerchantExperienceSettingsTab variant={getMerchantInfoCardVariant()} />}
  </>;
};

export default MerchantAccountInformation2;

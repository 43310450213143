import { useAuth } from 'auth/useAuth';
import { IUser } from 'common/interfaces';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'reducers/Hooks';
import useMerchant from './useMerchant';

export const useIsDtmEnabled = (userProp?: IUser) => {
  const merchant = useMerchant();
  const whitelabel = useAppSelector(state => state.whitelabel);
  const [user, setUser] = useState(useAuth()?.user);
  const [isDtmEnabled, setIsDtmEnabled] = useState<boolean>(null);
  const [ready, setReady] = useState(false); // This should only be true in case we receive not null userProp and isDtmEnabled is calculated based on that - if we don't pass userProp, we are not checking ready at all

  useEffect(() => {
    if (userProp) {
      setUser(userProp);
    }
  }, [userProp]);

  useEffect(() => {
    if (user) {
      if (user?.user_type === "MERCHANT" && merchant?.programs.includes("DTM") || (user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") && whitelabel?.programs.includes("DTM")) {
        setIsDtmEnabled(true);
      }
      else {
        setIsDtmEnabled(false);
      }

      if (userProp) {
        // set only in case userProp is not null, otherwise it will be set at the first load of the hook
        setReady(true);
      }
    }
  }, [merchant, whitelabel, user]);

  return { isDtmEnabled, ready };
};
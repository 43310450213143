import { IFilter, IList, ICancelLoanApplication } from "common/interfaces";
import { ENABLE_FINANCING_API_INSTANCE } from "./ActionConstants";
import ErrorHandler from "./ErrorHandler";
import Observer, { EVENTS } from "classes/Observer";
import { displayMiniFeedback } from "utils/helpers";
import ApiCache from "classes/ApiCache";
import IRefund from "common/interfaces/table/IRefund";

export default class LoanApplicationHandler {
  /**
   * @description Get list of applications
   * @param {string} next Used to get the next page.
   * @param {IFilter} filter Application filter.
   * @param {boolean} preventPagination Prevent breaking the result in different pages.
   * @returns {Promise<IList>} Applications.
   */
  static getMany = async (next: string, filter: IFilter, preventPagination: boolean = false, merchantIdOrSlug?: string, skipCache = false): Promise<IList> => {
    try {
      let url;
      if (merchantIdOrSlug) {
        url = next || `loan-applications/applications/?merchant=${merchantIdOrSlug}&offset=0&limit=30`;
      } else {
        url = next || `loan-applications/applications/?offset=0&limit=30`;
      }
      url = url.Filter(filter);

      if (preventPagination) {
        url = url.AddQuerystring("offset", "0").AddQuerystring("limit", "1000000");
      }

      const response = await ApiCache.get(url, false, skipCache);
      return Promise.resolve({
        ...response,
        originalUrl: url
      } as IList);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get application's history
   * @param {string} next Used to get the next page.
   * @param {string} application_id Application Id.
   * @returns {Promise<IList>} Application history.
   */
  static getHistory = async (next: string, application_id: string): Promise<IList> => {
    try {
      const url = next || `loan-applications/applications/${application_id}/history/?offset=0&limit=30`;
      const response = await ApiCache.get(url);
      return Promise.resolve({
        ...response,
        originalUrl: url
      } as IList);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get application's offers
   * @param {string} next Used to get the next page.
   * @param {string} application_id Application Id.
   * @returns {Promise<IList>} Application offers.
   */
  static getOffers = async (next: string, application_id: string): Promise<IList> => {
    try {
      const url = next || `loan-applications/applications/${application_id}/offers/?offset=0&limit=30`;
      const response = await ENABLE_FINANCING_API_INSTANCE.get(url);
      return Promise.resolve({
        ...response.data,
        originalUrl: url
      } as IList);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get application's details
   * @param {string} application_id Application Id.
   * @returns {Promise<any>} Application details.
   */
  static get = async (application_id: string, skipCache = true): Promise<any> => {
    try {
      const response = await ApiCache.get(`loan-applications/applications/${application_id}/`, false, skipCache);
      return Promise.resolve(response);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Update application location association.
   * @param {string} new_location_id New location.
   * @param {string} application_id Application id.
   * @returns {Promise<any>} API response.
   */
  static updateLocationAssociation = async (new_location_id: string, application_id: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`loan-applications/applications/${application_id}/`, {
        location_id: new_location_id
      });
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Update application user association.
   * @param {string} new_user_id New user.
   * @param {string} application_id Application id.
   * @returns {Promise<any>} API response.
   */
  static updateUserAssociation = async (new_user_id: any, application_id: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`loan-applications/applications/${application_id}/`, {
        created_by_id: new_user_id
      });
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Re-send offers email.
   * @param {string} application_id Application id.
   * @returns {Promise<any>} API response.
   */
  static resendOffersEmail = async (application_id: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.post(`loan-applications/applications/${application_id}/offers/resend/`);
      return Promise.resolve(response.data);
    } catch (error) {
      if ([400, 404, 429, 500].includes(error?.response?.status) && error?.response?.data?.error) {
        displayMiniFeedback(error?.response?.data?.error, true);
        return Promise.reject();
      }
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
 * @description Get application's offers details
 * although you get the list of offers, in case of this request for Special (pending=true), you should always get back only 1 offer
 * @param {string} next Used to get the next page.
 * @param {string} application_id Application Id.
 * @returns {Promise<IList>} Application offers.
 */
  static getOfferDetails = async (next: string, application_id: string): Promise<IList> => {
    try {
      const url = next || `loan-applications/applications/${application_id}/offers/?pending=true`;
      const response = await ENABLE_FINANCING_API_INSTANCE.get(url);
      return Promise.resolve({
        ...response.data,
        originalUrl: url
      } as IList);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
* @description Approve or deny offer
* @param {string} application_id Application Id.
* @param {"approve" | "deny"} decision Should the offer be approved or denied.
* @returns {Promise<any>} API response.
*/
  static approveOrDenyOffer = async (application_id: string, decision: "approve" | "decline"): Promise<any> => {
    try {
      const url = `loan-applications/applications/${application_id}/${decision}/`;
      const response = await ENABLE_FINANCING_API_INSTANCE.post(url);
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Request refund
   * @param {string} application_id Application Id.
   * @param {object} refund_body refund body.
   * @returns {Promise<any>} API response.
  */
  static requestRefund = async (application_id: string, refund: IRefund): Promise<any> => {
    try {
      const url = `loans/${application_id}/refunds/`;
      const response = await ENABLE_FINANCING_API_INSTANCE.post(url, refund);
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get application's refunds
   * @param {string} next Used to get the next page. 
   * @param {string} application_id Application Id.
   * @returns {Promise<IList>} Application refunds.
  */
  static getRefunds = async (next: string, application_id: string): Promise<IList> => {
    try {
      const url = next || `loans/${application_id}/refunds/?offset=0&limit=30`;
      const response = await ENABLE_FINANCING_API_INSTANCE.get(url);
      return Promise.resolve({
        ...response.data,
        originalUrl: url
      } as IList);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
  * @description Cancel loan application
  * @param {string} application_id Application Id.
  * @returns {Promise<any>} API response.
  */
  static cancelApplicationInvite = async (application_id: string, payload: ICancelLoanApplication): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.delete(`loan-applications/applications/${application_id}/`, { data: payload });
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }
}

import { CloseIcon } from "common";
import { Input, Form, EmptySpace } from "common/form";
import React from "react";
import { useAppDispatch } from "reducers/Hooks";
import { IPopup, IUser } from "common/interfaces";
import Popup from "./Popup";
import popUpActions from "reducers/PopUpReducer";
import LocalStorage from "classes/LocalStorage";
import UserHandler from "actions/UserHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { displayMiniFeedback } from "utils/helpers";
import { PasswordValidation, Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";

const UpdatePasswordPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const user = LocalStorage.get<IUser>("user");

  const handleClose = () => {
    dispatch(popUpActions.closePopup());
  }

  const handleSubmit = async (data) => {
    if (user?.id) {
      try {
        await UserHandler.updatePassword(data, user?.id);
        displayMiniFeedback("Password updated successfully");
        Analytics.track({ experience: "portal", screen: "settings_account_info", object: "user_password", action: "updated" } as ITracking);

      } catch (error) {
        console.error("Error:", error);

      } finally {
        handleClose();
      }
    }
  };

  return (
    <Popup isInner={true} styleOverride={{ padding: "1.5rem 3.5rem .5rem 3.5rem", gap: "0" }}>
      <>
        <CloseIcon id="updatePasswordPopup" color="dark" />
        <Form
          id="updatePasswordPopup"
          title="Update your password"
          submitBtnText="Save"
          onFormSubmit={handleSubmit}
          validationSchema={Schema({
            current_password: SimpleRequireValidation,
            new_password: PasswordValidation,
            // confirm_new_password: SimpleRequireValidation
            new_password2: SimpleRequireValidation
          })}
          values={{
            current_password: "",
            new_password: "",
            // confirm_new_password: "",
            new_password2: "",
          }}
          buttonPosition="bottom right"
          inputFields={[
            [
              <Input type="password" name="current_password" id="updatePasswordPopup_current_password" label="Enter your current password" />,
              <EmptySpace />
            ],
            [
              <Input type="password" name="new_password" id="updatePasswordPopup_new_password" label="Enter your new password" />,
              <Input type="password" name="new_password2" id="updatePasswordPopup_confirm_new_password" label="Confirm your new password" />,
            ]
          ]}
          passwordRequirementsCheckField="new_password"
          isInsidePopup
          useSmallerTitle
        />
      </>
    </Popup>
  );
};

export default UpdatePasswordPopup;

import React, { ReactNode } from "react";
import styles from "./InfoContainer.module.scss";
import { Edit, Icon, Link2 } from "iconsax-react";
import Hasher from "classes/Hasher";
import Button from "./Button";
import { renderChipIfPresent } from "utils/helpers";
import Link from "./Link";
import AppIcon from "./AppIcon";

interface IRow {
  label: string;
  value: string | ReactNode | Array<ReactNode>;
  // additional data for the same row
  description?: string;
  onClick?: () => void;
  // used for tracking, also doesn't create a button like onClick
  onLinkClick?: (name: string) => void;
  id?: string;
}

interface IQuickLink {
  label: string;
  onClick: () => void;
  id?: string;
}

interface IInfoContainer {
  id: string;
  children?: ReactNode;
  title?: string;
  icon: Icon;
  rows?: Array<IRow | IQuickLink>;
  onClick?: () => void;
  backgroundColor?: string;
  disabled?: boolean;
  style?: any;
  rowsAsLinks?: boolean;
}

const InfoContainer: React.FC<IInfoContainer> = ({ id, children, title, rows, onClick, backgroundColor, disabled, style, rowsAsLinks, icon }) => {

  let inlineStyle = {};
  if (backgroundColor) {
    inlineStyle["backgroundColor"] = backgroundColor;
  }
  if (disabled) {
    inlineStyle["backgroundColor"] = "var(--blurColor)";
  }

  return <div className={styles.infoContainer} style={style} id={id}>
    {title && <div className={styles.infoHeader}>
      {icon && <AppIcon color="var(--primaryTextColor)" size={22} icon={icon} circleBackground={true} />}
      <div>{title}</div>
    </div>}
    <div className={`${styles.infoBody} ${!title ? styles.noHeader : ''}`} style={inlineStyle}>
      {onClick && !disabled && <div className={styles.editInfo}>
        <Edit
          color="var(--primaryTextColor)"
          size={22}
          onClick={onClick}
          id={`${id}_edit_button`}
        />
      </div>}
      {rows && rows.map((row, index) => {
        const keyHash = Hasher.convert(`${title}-${row.label}-${index}`);
        if ((row as IRow).value || (row as IRow).value == null) {
          if (!Array.isArray((row as IRow).value)) {
            return <>
              <div className={styles.rowWrapper}>
                <div key={keyHash} className={styles.infoRow}>
                  {row.onClick && <Button label={row.label} id={`infoContainer_edit_${keyHash}`} onClick={row.onClick} style={{ width: "auto" }} variant="secondary" />}
                  {!row.onClick && <>
                    {rowsAsLinks && <>
                      <AppIcon color="var(--primaryTextColor)" size={22} icon={Link2} circleBackground={true} />
                      <Link id={id} href={(row as IRow).value.toString()} openNewTab={true} rel="noreferrer" linkText={row.label} onClick={() => (row as IRow)?.onLinkClick(row.label)} />
                    </>
                    }

                    {!rowsAsLinks && <>
                      <p className="menu" style={{ fontWeight: 600 }}>{row.label}</p>
                      <p {...row.id && { id: row.id }}>{renderChipIfPresent((row as IRow).value)}</p>
                    </>
                    }
                  </>}
                </div>
                {(row as IRow)?.description && <p className={styles.rowDescription}>{(row as IRow).description}</p>}
              </div>
            </>;
          } else {
            return <div key={keyHash} className={styles.infoRow}>
              {row.onClick && <Button label={row.label} id={`infoContainer_edit_${keyHash}`} onClick={row.onClick} style={{ width: "auto" }} variant="secondary" />}
              {!row.onClick && <>
                <p className="menu" style={{ fontWeight: 600 }}>{row.label}</p>
                <li style={{ listStyle: "none" }}>
                  {((row as IRow).value as Array<ReactNode>).map((arrayItem, arrayIndex) => {
                    return <ul
                      key={`${row.label}_${arrayIndex}`}
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: 600,
                        lineHeight: "1.8rem",
                        color: "var(--darkTextColor)",
                        marginBottom: ((row as IRow).value as Array<ReactNode>).length === 1 ? 0 : 10,
                        marginTop: 3
                      }}>{renderChipIfPresent(arrayItem)}</ul>
                  })}
                </li>
              </>}
            </div>;
          }
        } else {
          return <p key={keyHash} className="menu"
            onClick={(row as IQuickLink).onClick}
            style={{
              color: "var(--primaryTextColor)",
              cursor: "pointer",
              fontWeight: 600
            }}>
            {row.label}
          </p>
        }
      })}
      {children}
    </div>
  </div>;
};

export default InfoContainer;

import styles from "./MerchantTabs.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "reducers/Hooks";
import { Breadcrumbs, Button } from "common";
import { useParams } from "react-router-dom";
import { useAuth } from "auth/useAuth";
import MerchantInformationCard from "./MerchantInformationCard";
import { MerchantTabType } from "common/types";
import InvitationHandler from "actions/InvitationHandler";
import {
  askForConfirmation, displayLoadingBar, displayMiniFeedback, getStatus,
  hideLoadingBar, isForMerchantGlobal, isForPartnerGlobal, getAllStatuses
} from "utils/helpers";
import Analytics, { ITracking } from "classes/Analytics";
import Observer, { EVENTS } from "classes/Observer";
import Table, { TableRef } from "common/Table";
import { MerchantHandler } from "actions/MerchantHandler";
import { PartnerHandler } from "actions/PartnerHandler";
import UserHandler from "actions/UserHandler";
import { IHeader, IHighlight, IList } from "common/interfaces";
import { formatAPIDate, formatFullname } from "utils/formatters";
import { POPUPS } from "utils/constants";
import popUpActions from "reducers/PopUpReducer";
import { MerchantHouse } from "content/popups/feedback";
import AuthHandler from "actions/AuthHandler";
import LocationsHandler from "actions/LocationsHandler";
import useMerchant from "hooks/useMerchant";
import usePartner from "hooks/usePartner";
import _ from "lodash";

interface IMerchantUsersLocationsTab {
  variant: MerchantTabType;
}

const MerchantUsersLocationsTab: React.FC<IMerchantUsersLocationsTab> = ({ variant }) => {
  const user = useAuth()?.user;

  const dispatch = useAppDispatch();
  const { merchantSlug, partnerSlug } = useParams();
  const merchant = useMerchant();
  const partner = usePartner();
  const observerUserUpdated = Observer.useObserver(EVENTS.USER_UPDATED);
  const observerInvitationUpdated = Observer.useObserver(EVENTS.INVITATION_UPDATED);

  const tableUsersRef = useRef<TableRef>(null);
  const [headers, setHeaders] = useState([
    { label: "First Name", value: "first_name", size: 5 },
    { label: "Last Name", value: "last_name", size: 5 },
    { label: "Role", value: "group", size: 6 },
    { label: "Email", value: "email", size: 10 },
    { label: "Status", value: "status", size: 4 },
    { label: "Last login", value: "last_login", size: 5 },
    { label: "Actions", value: "actions", size: 7, preventSorting: true }
  ]);
  const [ready, setReady] = useState(false);
  const [isMerchantDeactivated, setIsMerchantDeactivated] = useState(false);
  const [isPartnerDeactivated, setIsPartnerDeactivated] = useState(false);
  const [merchantStatuses, setMerchantStatuses] = useState([]);

  // LOCATIONS
  const auth = useAuth();
  const [statuses, setStatuses] = useState([]);
  const observerLocationUpdated = Observer.useObserver(EVENTS.LOCATION_UPDATED);
  const tableLocationsRef = useRef<TableRef>(null);

  useEffect(() => {
    tableLocationsRef?.current?.reloadData();
  }, [observerLocationUpdated]);

  useEffect(() => {
    if (merchant || partner) {
      Promise.all([getStatus("Deactivated"), getStatus("Declined")])
        .then(responses => {
          if (merchant) {
            setIsMerchantDeactivated(merchant?.status === responses[0] || merchant?.status === responses[1]);
          }
          if (partner) {
            setIsPartnerDeactivated(partner?.status === responses[0] || partner?.status === responses[1]);
          }
        });
    }
  }, [merchant, partner]);

  useEffect(() => {
    getAllStatuses()
      .then(response => {
        setMerchantStatuses(response);
      });
  }, []);


  useEffect(() => {
    tableUsersRef?.current?.reloadData();
  }, [observerUserUpdated, observerInvitationUpdated]);

  useEffect(() => {
    if (user) {
      displayLoadingBar();
      if (!isForMerchant() && !isForPartner()) {
        setReady(true);
        hideLoadingBar();
      } else if (isForMerchant()) {
        MerchantHandler().get(merchantSlug || merchant?.slug)
          .then(() => {
            const headers: any = [
              { label: "First Name", value: "first_name", size: 4 },
              { label: "Last Name", value: "last_name", size: 5 },
              { label: "Role", value: "group", size: 4 },
              { label: "Email", value: "email", size: 11 },
              { label: "Status", value: "status", size: 5 },
              { label: "Locations", value: "primary_location", size: 5 },
              { label: "Last login", value: "last_login", size: 5 },
            ];
            if (user?.user_type !== "PARTNER") {
              headers.push({ label: "Actions", value: "actions", size: 7, preventSorting: true });
            }
            setHeaders(headers);
            setReady(true);
            hideLoadingBar();
          });
      } else if (isForPartner()) {
        PartnerHandler().get(partnerSlug || partner?.slug)
          .then(() => {
            setHeaders([
              { label: "First Name", value: "first_name", size: 4 },
              { label: "Last Name", value: "last_name", size: 5 },
              { label: "Role", value: "group", size: 4 },
              { label: "Email", value: "email", size: 11 },
              { label: "Status", value: "status", size: 5 },
              { label: "Last login", value: "last_login", size: 5 },
              { label: "Actions", value: "actions", size: 7, preventSorting: true }
            ])
            setReady(true);
            hideLoadingBar();
          });
      }
    }
  }, [user]);

  const isForMerchant = () => {
    return isForMerchantGlobal(user, merchantSlug);
  }

  const isForPartner = () => {
    return isForPartnerGlobal(user, partnerSlug, merchantSlug);
  }

  const getListUsers = async (next: string): Promise<IList> => {
    let list = await UserHandler.getAll(next,
      user?.user_type, merchant && merchantSlug ? merchant?.id : null,
      partner && partnerSlug ? partner?.id : null);
    list.results = list.results.map(result => {
      return {
        ...result,
        name: formatFullname(result?.first_name, result?.last_name),
        group: getRole(result.group),
        primary_location_id: result.primary_location?.id,
        primary_location: result.primary_location?.name,
        last_login: formatAPIDate(result.last_login)
      }

    });
    return list;
  }

  const getRole = (group: string): string => {
    return group?.replace('White Label ', '').replace('Merchant ', '').replace('Partner ', '') || '';
  }

  const resetPassword = async (data: any) => {
    await AuthHandler.resetPassword(data?.email);
    Analytics.track({ experience: "portal", screen: getScreenName(), object: "password_reset_email_action", action: "successful" } as ITracking);
    displayMiniFeedback("Password reset request sent");
    dispatch(popUpActions.closePopup());
  };

  const getScreenName = () => {
    if (!partnerSlug && !merchantSlug) {
      return "settings_users";
    } else if (partnerSlug) {
      return "partners_users"
    } else {
      return "merchants_users";
    }
  }

  const handleStatusChange = (data: any, newStatus: "Active" | "Deactivated") => {
    if (data.id) {
      const payload = {
        status: newStatus.toUpperCase()
      };
      UserHandler.update(data.id, payload)
        .then(() => {
          Analytics.track({ experience: "portal", screen: getScreenName(), object: "user", action: newStatus === "Active" ? "activated" : "deactivated" } as ITracking);
          displayMiniFeedback(`User is now ${newStatus.toLowerCase()}`);
          dispatch(popUpActions.closePopup());
          Observer.trigger(EVENTS.USER_UPDATED);
        });
    }
  };

  // LOCATIONS

  const update = (location: any, status: "Active" | "Deactivated") => {
    const locationDeactivated = {
      name: location.name || location.location_name,
      status: statuses.find(locStatus => { return locStatus.label.Equals(status) })?.value
    };

    LocationsHandler.update(locationDeactivated, location.id)
      .then(() => {
        Analytics.track({ experience: "portal", screen: merchantSlug ? "merchants_locations" : "settings_locations", object: "location", action: status === "Active" ? "activated" : "deactivated" } as ITracking);
        displayMiniFeedback(`${location.name} is now ${status.toLowerCase()}`);
        dispatch(popUpActions.closePopup());
      });
  };

  const makeLocationPrimary = (item: any) => {
    const payload = {
      primary_location: item.id,
    }
    if (auth?.user?.id) {
      UserHandler.updateMerchantUserForWL(merchant?.id, auth.user.id, payload)
        .then(() => {
          Analytics.track({ experience: "portal", screen: "settings_locations", object: "primary_location", action: "updated" } as ITracking);
          UserHandler.getProfile()
            .then(response => {
              auth.setUser(response);
              dispatch(popUpActions.closePopup());
              Observer.trigger(EVENTS.LOCATION_UPDATED);
            });
        });
    }
  };

  const getData = async (next: string): Promise<IList> => {
    let list = await LocationsHandler.getAll(next, merchantSlug || merchant?.slug, null, null, null, true);
    list.results = list.results.map(location => {
      return {
        ...location,
        highlight: { highlighted: location.id === auth?.user?.primary_location && auth?.user?.user_type === "MERCHANT", property: "name", chip: "primary" } as IHighlight
      };
    });
    return Promise.resolve(list);
  }

  const isMerchantDeactivatedLoc = () => {
    const deactivatedStatus = statuses.find(status => { return status.label === "Deactivated" })?.id;
    return deactivatedStatus === merchant?.status;
  }

  const getTableHeaders = () => {
    let headers: IHeader[] = [
      { label: "Location name", value: "name", size: 9, sortBy: "location__name" },
      { label: "Address", value: "address1", size: 8 },
      { label: "City", value: "city", size: 7 },
      { label: "State", value: "state", size: 4 },
      { label: "Postal code", value: "postal_code", size: 5 },
      { label: "Status", value: "status", size: 5 }];

    // partner admin has to be able to edit declined merchants
    if (user?.user_type !== "PARTNER" || !isMerchantDeactivatedLoc()) {
      headers.push({ label: "Actions", value: "actions", size: 7, preventSorting: true, align: "left" });
    }
    return headers;
  }

  const getStatusId = (status: "Active" | "Pending" | "Deactivated" | "Declined"): number => {
    return _.find(merchantStatuses, item => { return item.name === status })?.id;
  }

  const renderMerchantHouse = () => {
    return (
      <>
        <div className={styles.approval}>
          <MerchantHouse />
          <h1>Merchant {merchant?.name} is pending approval</h1>
        </div>
      </>
    );
  }

  const renderUsersSection = () => {
    return (
      <>
        {/* USERS START */}
        <div className={styles.titleContainer}>
          {user?.user_type === "WHITELABEL" && merchantSlug && merchant && merchant?.name && <Breadcrumbs pageTitle={`${merchant?.name} users`} />}
          {user?.user_type === "WHITELABEL" && partnerSlug && partner && <h2 className={styles.pageTitle}>{`${partner?.name} users`}</h2>}
          <Button
            type="button"
            id="invite-user-btn"
            label="Invite user"
            onClick={() => {
              Analytics.track({ experience: "portal", screen: getScreenName(), object: "invite_user_button", action: "clicked" } as ITracking);
              dispatch(popUpActions.openPopup(POPUPS.INVITE_USER));
            }}
          />
        </div>

        {ready && <div className={styles.tableContainer}>
          <Table
            maxHeight={450}
            data={getListUsers}
            headers={headers}
            id="userData"
            ref={tableUsersRef}
            tableBodyStyle={{ minWidth: 1300 }}
            action={[
              {
                label: "Edit", value: (item) => {
                  dispatch(popUpActions.openPopup({ name: POPUPS.EDIT_USER, message: item }));
                  return Promise.resolve();
                },
                visible: (item: any) => {
                  if (item.status !== "DEACTIVATED" &&
                    ((isForMerchant() && !isMerchantDeactivated) ||
                      (isForPartner() && !isPartnerDeactivated) ||
                      (!isForMerchant() && !isForPartner()))) {
                    return true;
                  }
                }
              },
              {
                label: "Password reset", value: (item) => {
                  askForConfirmation(`Are you sure you want to send a password reset email to ${formatFullname(item?.first_name, item?.last_name)}?`,
                    { text: 'Yes', action: () => { resetPassword(item); } },
                    { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });

                  return Promise.resolve();
                },
                visible: (item: any) => {
                  if ((item.status !== "INVITED" &&
                    item.status !== "DEACTIVATED" &&
                    item.status !== "CANCELED") &&
                    ((isForMerchant() && !isMerchantDeactivated) ||
                      (isForPartner() && !isPartnerDeactivated) ||
                      (!isForMerchant() && !isForPartner()))) {
                    return true;
                  }
                }
              },
              {
                label: "Deactivate", value: (item) => {
                  askForConfirmation(`Are you sure you want to deactivate ${formatFullname(item?.first_name, item?.last_name)}?`,
                    { text: 'Yes, deactivate', action: () => { handleStatusChange(item, "Deactivated"); } },
                    { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
                  return Promise.resolve();
                },
                visible: (item: any) => {
                  if (item.status === "ACTIVE") {
                    return true;
                  }
                }
              },
              {
                label: "Activate",
                value: (item) => {
                  askForConfirmation(`Are you sure you want to activate ${formatFullname(item?.first_name, item?.last_name)}?`,
                    { text: 'Yes, I do', action: () => { handleStatusChange(item, "Active"); } },
                    { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
                  return Promise.resolve();
                },
                visible: (item: any) => {
                  if (item.status === "DEACTIVATED") {
                    return true;
                  }
                }
              },
              {
                label: "Resend",
                value: (item: any) => {
                  return InvitationHandler.resendOrCancelUserInvitation(user, item?.id, "Resend")
                    .then(() => {
                      displayMiniFeedback("Invitation has been sent");
                    });
                },
                visible: (item: any) => {
                  if (item.status === "INVITED" || item.status === "CANCELED") {
                    return true;
                  }
                }
              },
              {
                label: "Cancel",
                value: (item) => {
                  return InvitationHandler.resendOrCancelUserInvitation(user, item?.id, "Cancel")
                    .then(() => {
                      Analytics.track({ experience: "portal", screen: getScreenName(), object: "invitation_cancel_action", action: "successful" } as ITracking);
                    });
                },
                visible: (item: any) => {
                  if (item.status === "INVITED") {
                    return true;
                  }
                }
              }
            ]}
            minDropdownWidth={140}// based on "Password reset"
          />
        </div>}
        {/* USERS END */}
      </>
    );
  }

  const renderLocationsSection = () => {
    return (
      <>
        {/* LOCATIONS START */}
        {ready && <>
          <div className={styles.buttonContainer}>
            {/* partner admin has to be able to edit declined merchants */}
            {(user?.user_type !== "PARTNER" || !isMerchantDeactivatedLoc()) &&
              <Button
                type="button"
                id="merchant_settings_addLocation"
                label="Add location"
                onClick={() => {
                  Analytics.track({ experience: "portal", screen: auth?.user?.user_type === "WHITELABEL" ? "merchants_locations" : "settings_locations", object: "add_location_button", action: "clicked" } as ITracking, { merchant_id: merchant?.id });
                  dispatch(popUpActions.openPopup(POPUPS.ADD_NEW_LOCATION));
                }}
              />
            }
          </div>
          <div className={styles.tableContainer}>
            <Table
              data={getData}
              maxHeight={450}
              headers={getTableHeaders()}
              ref={tableLocationsRef}
              tableBodyStyle={{ minWidth: 1200 }}
              id="merchant_settings_locations"
              // partner admin has to be able to edit declined merchants
              action={(user?.user_type === "PARTNER" && isMerchantDeactivatedLoc()) ? [] : [
                {
                  label: "Edit",
                  value: (item) => {
                    dispatch(popUpActions.openPopup({ name: POPUPS.EDIT_LOCATION, message: item }));
                    return Promise.resolve();
                  },
                  visible: (item: any) => {
                    if (!isMerchantDeactivatedLoc() && !item.status.Equals("Deactivated")) {
                      return true;
                    }
                  }
                },
                {
                  label: "Activate",
                  value: (item) => {
                    askForConfirmation(`Are you sure you want to activate ${item?.name}?`,
                      { text: 'Yes, I do', action: () => { update(item, "Active") } },
                      { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
                    return Promise.resolve();
                  },
                  visible: (item: any) => {
                    if (item.status.Equals("Deactivated")) {
                      return true;
                    }
                  }
                },
                {
                  label: "Deactivate",
                  value: (item) => {
                    askForConfirmation(`Are you sure you want to deactivate ${item?.name}?`,
                      { text: 'Yes, deactivate', action: () => { update(item, "Deactivated") } },
                      { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
                    return Promise.resolve();
                  },
                  visible: (item: any) => {
                    if (!item.status.Equals("Deactivated")) {
                      return true;
                    }
                  }
                },
                {
                  label: "Make primary",
                  value: (item) => {
                    askForConfirmation(`Are you sure you want to make ${item?.name} the primary location?`,
                      { text: 'Yes, make primary', action: () => { makeLocationPrimary(item) } },
                      { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
                    return Promise.resolve();
                  },
                  visible: (item: any) => {
                    if (item.id !== auth?.user?.primary_location && !item.status.Equals("Deactivated") && auth?.user?.user_type === "MERCHANT") {
                      return true;
                    }
                  }
                }
              ]}
              minDropdownWidth={125} // based on "Make primary"
            />
          </div>
        </>}
        {/* LOCATIONS END */}
      </>
    );
  }

  return <>
    <MerchantInformationCard variant={variant} />

    {merchant && <div className={styles.contentContainer}>
      {
        merchant?.status === getStatusId("Pending") && user?.user_type === "PARTNER"
          ? renderMerchantHouse()
          : <>
            {renderUsersSection()}
            {renderLocationsSection()}
          </>
      }
    </div>}
  </>;
};

export default MerchantUsersLocationsTab;

import AuthHandler from "actions/AuthHandler";
import UserHandler from "actions/UserHandler";
import { useAuth } from "auth/useAuth";
import Analytics, { ITracking } from "classes/Analytics";
import Observer, { EVENTS } from "classes/Observer";
import Table, { TableRef } from "common/Table";
import { IList } from "common/interfaces";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "reducers/Hooks";
import { formatAPIDate, formatFullname } from "utils/formatters";
import { askForConfirmation, displayMiniFeedback, getRole, isForMerchantGlobal, isForPartnerGlobal } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { POPUPS } from "utils/constants";
import InvitationHandler from "actions/InvitationHandler";
import { useParams } from "react-router";
import useMerchant from "hooks/useMerchant";
import usePartner from "hooks/usePartner";

const UsersTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAuth()?.user;
  const tableRef = useRef<TableRef>(null);
  const [ready, setReady] = useState(false);
  const observer = Observer.useObserver(EVENTS.USER_UPDATED);
  const { merchantSlug, partnerSlug } = useParams();
  const merchant = useMerchant();
  const partner = usePartner();

  useEffect(() => {
    tableRef?.current?.reloadData();
  }, [observer]);

  useEffect(() => {
    if (user) {
      setReady(true);
    }
  }, [user]);

  const getListUsers = async (next: string): Promise<IList> => {
    let list = await UserHandler.getAll(next, user?.user_type, null, null);
    list.results = list.results.map(result => {
      return {
        ...result,
        name: formatFullname(result?.first_name, result?.last_name),
        group: getRole(result.group),
        last_login: formatAPIDate(result.last_login)
      }
    });
    return list;
  }

  const resetPassword = async (data: any) => {
    await AuthHandler.resetPassword(data?.email);
    Analytics.track({ experience: "portal", screen: "settings_users", object: "password_reset_email_action", action: "successful" } as ITracking);
    displayMiniFeedback("Password reset request sent");
    dispatch(popUpActions.closePopup());
  };

  const isForMerchant = () => {
    return isForMerchantGlobal(user, merchantSlug);
  }

  const isForPartner = () => {
    return isForPartnerGlobal(user, partnerSlug, merchantSlug);
  }

  const handleStatusChange = (data: any, newStatus: "Active" | "Deactivated") => {
    if (data.id) {
      const payload = {
        status: newStatus.toUpperCase()
      };
      UserHandler.update(data.id, payload)
        .then(() => {
          Analytics.track({ experience: "portal", screen: "settings_users", object: "user", action: newStatus === "Active" ? "activated" : "deactivated" } as ITracking);
          displayMiniFeedback(`User is now ${newStatus.toLowerCase()}`);
          dispatch(popUpActions.closePopup());
          Observer.trigger(EVENTS.USER_UPDATED);
        });
    }
  };

  return <>
    {ready && <Table
      maxHeight={450}
      data={getListUsers}
      headers={[
        { label: "First Name", value: "first_name", size: 5 },
        { label: "Last Name", value: "last_name", size: 5 },
        { label: "Role", value: "group", size: 6 },
        { label: "Email", value: "email", size: 12 },
        { label: "Status", value: "status", size: 4 },
        { label: "Last login", value: "last_login", size: 5 },
        { label: "Actions", value: "actions", size: 7, preventSorting: true }
      ]}
      id="userData"
      ref={tableRef}
      tableBodyStyle={{ minWidth: 1200 }}
      action={[
        {
          label: "Edit", value: (item) => {
            dispatch(popUpActions.openPopup({ name: POPUPS.EDIT_USER, message: item }));
            return Promise.resolve();
          },
          visible: (item: any) => {
            if (item.status !== "DEACTIVATED") {
              return true;
            }
          }
        },
        {
          label: "Password reset", value: (item) => {
            askForConfirmation(`Are you sure you want to send a password reset email to ${formatFullname(item?.first_name, item?.last_name)}?`,
              { text: 'Yes, I do', action: () => { resetPassword(item); } },
              { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
            return Promise.resolve();
          },
          visible: (item: any) => {
            if (item.status !== "INVITED" &&
              item.status !== "DEACTIVATED" &&
              item.status !== "CANCELED") {
              return true;
            }
          }
        },
        {
          label: "Deactivate", value: (item) => {
            askForConfirmation(`Are you sure you want to deactivate ${formatFullname(item?.first_name, item?.last_name)}?`,
              { text: 'Yes, deactivate', action: () => { handleStatusChange(item, "Deactivated"); } },
              { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
            return Promise.resolve();
          },
          visible: (item: any) => {
            if (item.status === "ACTIVE") {
              return true;
            }
          }
        },
        {
          label: "Activate",
          value: (item) => {
            askForConfirmation(`Are you sure you want to activate ${formatFullname(item?.first_name, item?.last_name)}?`,
              { text: 'Yes, I do', action: () => { handleStatusChange(item, "Active"); } },
              { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
            return Promise.resolve();
          },
          visible: (item: any) => {
            if (item.status === "DEACTIVATED") {
              return true;
            }
          }
        },
        {
          label: "Resend",
          value: (item: any) => {
            return InvitationHandler.resendOrCancelUserInvitation(user, item?.id, "Resend")
              .then(() => {
                displayMiniFeedback("Invitation has been sent");
              });
          },
          visible: (item: any) => {
            if (item.status === "INVITED" || item.status === "CANCELED") {
              return true;
            }
          }
        },
        {
          label: "Cancel",
          value: (item) => {
            return InvitationHandler.resendOrCancelUserInvitation(user, item?.id, "Cancel")
              .then(() => {
                Analytics.track({ experience: "portal", screen: "settings_users", object: "invitation_cancel_action", action: "successful" } as ITracking);
              });
          },
          visible: (item: any) => {
            if (item.status === "INVITED") {
              return true;
            }
          }
        }
      ]}
      minDropdownWidth={140}// based on "Password reset"
    />}
  </>;
}

export default UsersTable;

import axios from "axios";
import Environment from "classes/Environment";
import Log from "classes/Log";
import version from "version.json";
import AuthHandler from "./AuthHandler";
import LocalStorage from "classes/LocalStorage";
import EnableCache from "classes/EnableCache";

if (version.commit_version) {
  Log.info(`%c ENV ${Environment.environmentName} COMMIT: ${version.commit_version} BASE_URL: ${process.env.REACT_APP_DB_URL}}`, `color: white; background-color: purple; padding: 5px; border-radius: 5px;`);
}

// Enable finance API
export const ENABLE_FINANCING_API_INSTANCE = axios.create({
  baseURL: process.env.REACT_APP_DB_URL
});

// Enable finance API without token header
export const ENABLE_FINANCING_API_INSTANCE_NO_TOKEN = axios.create({
  baseURL: process.env.REACT_APP_DB_URL
});

const handleTokenExpiration = async error => {
  const originalRequest = error.config;

  if (!LocalStorage.get("refresh_token")) {
    window.location.href = '/login';
    return Promise.reject(error);
  }

  // Skip refresh if we're on the login page or accessing specific URL
  if (window.location.pathname === '/login' && originalRequest.url.includes('core/feature-flags')) {
    return Promise.reject(error);
  }

  if (error?.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    try {
      const newToken = await AuthHandler.refreshToken();

      // Update global header
      ENABLE_FINANCING_API_INSTANCE.defaults.headers['Authorization'] = `Bearer ${newToken}`;

      // Resend original request
      originalRequest.headers = Object.assign({}, originalRequest.headers, {
        'Authorization': `Bearer ${newToken}`
      });

      return ENABLE_FINANCING_API_INSTANCE(originalRequest);

    } catch (refreshError) {
      delete ENABLE_FINANCING_API_INSTANCE.defaults.headers['Authorization'];
      LocalStorage.clear(true);
      // Redirect to login page if refresh token is not valid
      window.location.href = '/login';
    }
  }

  return Promise.reject(error);
};

const cleanCacheMethods: Array<string> = ['POST', 'PUT', 'DELETE', 'PATCH'];
const requestStyle = `color: white; background-color: blue; padding: 5px; border-radius: 5px;`;
const responseStyle = `color: white; background-color: green; padding: 5px; border-radius: 5px;`;

const requestInterceptor = (request: any, useToken: boolean) => {
  const method = request.method.toUpperCase();
  if (cleanCacheMethods.includes(method)) {
    EnableCache.clear();
  }

  addBusinessModelParam(request);

  if (window.EnableDebug) {
    request["metadata"] = { url: `${method} ${request.url}${handleParams(request)}` };
    let logLine = `REQUEST ${request["metadata"]?.url}`;
    if (request.data instanceof FormData) {
      Log.info(`%c ${logLine}`, requestStyle, [...request.data.entries()].map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1].toString())));
    } else {
      const body = Object.assign({}, request?.data);
      if (body?.password) {
        body.password = body.password.replace(/./g, "*");
      }
      Log.info(`%c ${logLine}`, requestStyle, request.method.toUpperCase() !== "GET" ? (body || "") : "");
    }
  }
  try {
    request.headers['X-Client-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (error) {
    console.log(error);
  }
  const token = LocalStorage.get("access_token");
  if (useToken && token) {
    request.headers['Authorization'] = `Bearer ${token}`;
  }
  return request;
};

const handleParams = (request: any): string => {
  const params = new URLSearchParams(request.params);
  return params.toString() ? `${request.url.includes('?') ? '&' : '?'}${params}` : '';
}

// add business model query parameter to any GET request
const addBusinessModelParam = (request: any) => {
  const method = request.method.toUpperCase();

  if (method === "GET") {
    const urlPattern = /\/loan-applications\/applications\/\d+\/\?vuid=[0-9a-fA-F-]+/;
    const url = new URL(request.url, window.location.origin);
    const params = new URLSearchParams(url.search);

    if (urlPattern.test(request.url) && params.has("bm")) {
      // Remove 'bm' param if the URL matches the pattern
      params.delete("bm");
      request.url = `${url.origin}${url.pathname}?${params.toString()}`;
    } else if (!params.has("bm")) {
      const businessModel = LocalStorage.get<string>("business_model");
      if (businessModel === "DTC" || businessModel === "DTM") {
        request.params = {
          ...request.params,
          bm: businessModel,
        };
      }
    }
  }
}

const responseInterceptor = (response: any) => {
  if (window.EnableDebug) {
    let logLine = `RESPONSE ${response.config["metadata"]?.url || ''}`;
    Log.info(`%c ${logLine}`, responseStyle, response.data);
  }
  return response;
};

// Intercept request
ENABLE_FINANCING_API_INSTANCE.interceptors.request.use(
  request => requestInterceptor(request, true),
  error => { return Promise.reject(error); }
);

// Intercept response
ENABLE_FINANCING_API_INSTANCE.interceptors.response.use(
  responseInterceptor,
  handleTokenExpiration
);

// Intercept request
ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.interceptors.request.use(
  request => requestInterceptor(request, false),
  error => {
    return Promise.reject(error);
  });

// Intercept response
ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.interceptors.response.use(
  responseInterceptor,
  error => { return Promise.reject(error); }
);

// USPS API
export const USPS_API_INSTANCE = axios.create({
  baseURL: 'https://secure.shippingapis.com/'
});

// SMARTY API
export const SMARTY_API_INSTANCE = axios.create({
  baseURL: 'https://us-autocomplete-pro.api.smarty.com/'
});
import SystemHandler from "actions/SystemHandler";
import React, { useEffect, useState } from "react";
import { splitFormInputFieldList } from "utils/helpers";
import { Form, Input, SelectDropdown, UploadFile } from "common/form";
import * as Yup from "yup";
import { ColorPalleteDropdown, LandingPagePreview } from "common";

interface IEditMerchantLandingPageTemplate {
  onSubmit: (data: any, logo: any) => void;
  slug?: string;
  primary_logo?: string;
  color_theme?: string;
  industry_template?: string;
  title?: string;
  hideLogo?: boolean;
}

const EditMerchantLandingPageTemplate: React.FC<IEditMerchantLandingPageTemplate> = ({
  onSubmit, slug, primary_logo, color_theme, industry_template, title, hideLogo }) => {

  const [colors, setColors] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [logo, setLogo] = useState(primary_logo);
  const [file, setFile] = useState(null);
  const [newSlug, setNewSlug] = useState(slug);
  const [colorTheme, setColorTheme] = useState(null);
  const [colorOptions, setColorOptions] = useState(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    Promise.all([
      SystemHandler.getTemplates(),
      SystemHandler.getColorTheme()
    ]).then(responses => {
      setTemplates(responses[0]);
      setColorOptions(responses[1]?.results?.map((item: any) => { return { value: item?.id, colors: item?.colors?.map(color => `#${color}`) }; }));
      setReady(true);
    });
  }, []);

  useEffect(() => {
    if (ready) {
      if (!selectedTemplate && industry_template) {
        setSelectedTemplate(templates.find((temp: any) => { return temp.id === industry_template }));
      } else {
        setSelectedTemplate(templates.find((temp: any) => { return temp.name === "Default" }));
      }
      if (color_theme) { setColors(colorOptions.find((color: any) => color.value === color_theme)?.colors) }
      if (primary_logo) { setLogo(primary_logo); }
    }
  }, [ready]);

  const handleSubmit = async (data: any) => {
    onSubmit(data, file);
  }

  const updateLogo = (logo: any) => {
    setLogo(URL.createObjectURL(logo));
    setFile(logo);
  }

  const validationSchema = Yup.object({
    slug: Yup.string()
      .matches(/^[A-Za-z0-9]+(?:[-_][A-Za-z0-9]+)*$/gi, "Only letters, numbers, and the symbols - and _ are allowed")
      .min(6, "URL needs to be more than 6 characters.")
      .required("This is a required field")
  });

  const getInputFields = () => {
    const inputFieldList = [
      <Input onChange={(e: any) => setNewSlug(e.target.value)} name="slug" id="editMerchantLandingPageTemplate_slug" label="Landing page URL*" />,
    ];

    if (!hideLogo) {
      inputFieldList.push(
        <UploadFile tooltip="Image criteria for logos: At least 100px wide, transparent or white background, and PNG or SVG." name="primary_logo" id="logo" label="Logo" onFileSelection={(file: any) => updateLogo(file)} displayLogo={false} />,
      );
    }

    inputFieldList.push(
      <ColorPalleteDropdown onChange={(value: string) => setColorTheme(value)} onColorChange={(colors: Array<string>) => setColors(colors)} name="color_theme" id="editMerchantLandingPageTemplate_color" label="Landing page color" />,
      <SelectDropdown onChange={(value: any) => { setSelectedTemplate(templates.find((temp: any) => temp.value === value)) }} name="industry_template" id="editMerchantLandingPageTemplate_industry" label="Industry" selectOptions={templates} />,
    );

    const inputFields = splitFormInputFieldList(inputFieldList, false);
    return inputFields;
  }

  return <>
    {ready && <>
      <Form
        id="editMerchantLandingPageTemplate"
        title={title || "Landing page"}
        submitBtnText="Save"
        onFormSubmit={handleSubmit}
        validationSchema={validationSchema}
        values={{
          slug,
          primary_logo,
          color_theme,
          industry_template
        }}
        buttonPosition={getInputFields()[1]?.length % 2 === 0 ? "bottom right" : "side right"}
        inputFields={getInputFields()}
        isInsidePopup
        useSmallerTitle
      />
      <p className="fontText" style={{ marginBottom: "2rem" }}>What you see below is a preview, you can always change it later in the settings.</p>
      <div className="logoPreview" style={{ border: "1px solid var(--primaryColor20Solid)", position: "relative", borderRadius: "2rem 2rem 2.6rem 2.6rem" }}>
        <LandingPagePreview
          hero={selectedTemplate?.hero_image || "/assets/images/merchantLandingBanner.png"}
          subtitle={selectedTemplate?.body || null}
          title={selectedTemplate?.title || null}
          colors={colors}
          {...logo && { preview_logo: logo }}
          forceMerchant={true} />
      </div>
    </>
    }
  </>;
}

export default EditMerchantLandingPageTemplate;
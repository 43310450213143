import SystemHandler from "actions/SystemHandler";
import {
  IAddressInfo, IDTCFinancialInfo, IDTMFinancialInfo, ILoanInformation, IMainContactBorrower,
  IPersonalInformation, ISSN, IStepper
} from "common/interfaces";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { DEFAULT_MAX_LOAN_AMOUNT, TODAY } from "utils/constants";
import { scrollTop } from "utils/helpers";
import {
  AddressValidation, AnnualIncomeValidation, EmailValidation, LoanAmountValidation,
  NameValidation, PersonalInfoValidation, PhoneValidation, getSSNValidation, SimpleRequireValidation, getSSNorITINvalidation
} from "utils/validation/additionalValidation";
import borrowerActions from "reducers/BorrowerReducer";
import LocalStorage from "classes/LocalStorage";
import BorrowerHandler from "actions/BorrowerHandler";
import { convertDateFormatSlashToDash, formatPhone, stripOutNonDigits } from "utils/formatters";
import moment from "moment";
import { DTM_VALIDATION_MATRIX, EmploymentDateValidation } from "utils/validation/employmentDateValidation";
import { RequireDateInRangeValidation } from "utils/validation/dateValidation";
import useMerchant from "hooks/useMerchant";
import useGetLoanApplication from "hooks/useGetLoanApplication";

export function useConfirmation(props: IStepper) {
  const dispatch = useAppDispatch();
  const merchant = useMerchant();
  const appInformation = useAppSelector(state => state.borrower);
  const lenderType = useAppSelector(state => state.borrower.lender_type);
  const loanPurposeOptions = useRef([]);
  const propertyStatusOptions = useRef([]);
  const employmentStatusOptions = useRef([]);
  const payFrequencyOptions = useRef([]);
  const creditRatingOptions = useRef([]);
  const educationLevelOptions = useRef([]);

  const [ready, setReady] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [validationSchema, setValidationSchema] = useState(null);
  const [displayEmploymentStartDate, setDisplayEmploymentStartDate] = useState(true);
  const [displayEmploymentEndDate, setDisplayEmploymentEndDate] = useState(true);
  const [employmentStatus, setEmploymentStatus] = useState(null);

  useGetLoanApplication();

  useEffect(() => {
    Promise.all([
      SystemHandler.getPropertyStatusOptions(),
      SystemHandler.getEmploymentStatusOptions(),
      SystemHandler.getPayFrequencyOptions(),
      SystemHandler.getCreditRatingOptions(),
      SystemHandler.getLoanPurposeOptions(),
      SystemHandler.getEducationLevelOptions()
    ]).then(responses => {
      propertyStatusOptions.current = responses[0];
      employmentStatusOptions.current = responses[1];
      payFrequencyOptions.current = responses[2];
      creditRatingOptions.current = responses[3];
      loanPurposeOptions.current = responses[4];
      educationLevelOptions.current = responses[5];
      setReady(true);
      scrollTop();
    });
  }, []);

  useEffect(() => {
    if (ready) {
      setEmploymentStatus((lenderType === "DTC" ? appInformation.dtcFinancialInformation : appInformation.dtmFinancialInformation)?.employment_status);
      setInitialValues({
        ...(lenderType === "DTC" && appInformation.loanInformation),
        ...appInformation.personalInformation,
        ...appInformation.contactInformation,
        ...appInformation.addressInformation,
        ...appInformation.socialSecurity,
        phone_number: formatPhone(appInformation.contactInformation?.phone_number),
        ...(lenderType === "DTC" ? appInformation.dtcFinancialInformation : {})
      });
      setValidationSchema({
        ssn: lenderType === "DTM" ? getSSNorITINvalidation(false) : getSSNValidation(false),
        ...PersonalInfoValidation,
        ...(lenderType === "DTC" && { education_level: SimpleRequireValidation }),
        loan_amount: (lenderType === "DTC" && LoanAmountValidation(merchant?.max_loan_amount || DEFAULT_MAX_LOAN_AMOUNT)),
        ...(loanPurposeOptions.current.length > 1 && lenderType === "DTC" && { loan_purpose: SimpleRequireValidation }),
        ...(lenderType === "DTC" && { employment_status: SimpleRequireValidation }),
        ...(lenderType === "DTC" && { pay_frequency: SimpleRequireValidation }),
        annual_income: AnnualIncomeValidation,
        ...(lenderType === "DTC" && { credit_rating: SimpleRequireValidation }),
        email: EmailValidation,
        phone_number: PhoneValidation,
        ...AddressValidation,
      });
    }
  }, [ready]);

  const handleSubmit = async (data: any): Promise<void> => {
    const applicationId = LocalStorage.get<number>("application_id");
    const vuid = LocalStorage.get<string>("vuid");

    const location = { location_id: LocalStorage.get<any>("selected_location")?.id };
    const loanInformation: ILoanInformation = lenderType === "DTM" ? null : { loan_amount: parseFloat(stripOutNonDigits(data.loan_amount.toString())), loan_purpose: lenderType === "DTM" ? null : data.loan_purpose };
    const personalInformation: IPersonalInformation = { first_name: data.first_name, last_name: data.last_name, date_of_birth: data.date_of_birth, education_level: lenderType === "DTM" ? null : data.education_level, annual_income: lenderType === "DTC" ? null : data.annual_income };
    const contactInformation: IMainContactBorrower = { email: data.email, phone_number: stripOutNonDigits(data.phone_number) };
    const addressInformation: IAddressInfo = { address1: data.address1, city: data.city, state: data.state, postal_code: data.postal_code, property_status: data.property_status };
    const dtcFinancialInformation: IDTCFinancialInfo = { employment_status: data.employment_status, annual_income: parseInt(stripOutNonDigits((data.annual_income || 0).toString())), pay_frequency: data.pay_frequency, credit_rating: data.credit_rating };
    const dtmFinancialInformation: IDTMFinancialInfo = { employment_status: data.employment_status, annual_income: parseInt(stripOutNonDigits((data.annual_income || 0).toString())), pay_frequency: data.pay_frequency, credit_rating: data.credit_rating, company_name: data.company_name, supervisor_full_name: data.supervisor_full_name, employment_start_date: data.employment_start_date, employment_end_date: data.employment_end_date, first_payment_date: data.first_payment_date };
    const ssnInformation: ISSN = { ssn: data.ssn };
    const additionalInformation: any = appInformation.additionalInformation ? { ...appInformation.additionalInformation } : null;

    const response = await BorrowerHandler.patch({
      ...location,
      ...loanInformation,
      ...personalInformation,
      ...contactInformation,
      ...addressInformation,
      ...ssnInformation,
      date_of_birth: convertDateFormatSlashToDash(data.date_of_birth),
      ...(lenderType === "DTC" ? dtcFinancialInformation : {}),
      ...additionalInformation
    }, applicationId, vuid);

    if (response) {
      // update redux
      dispatch(borrowerActions.setLoanInformation(loanInformation));
      dispatch(borrowerActions.setPersonalInformation(personalInformation));
      dispatch(borrowerActions.setContactInformation(contactInformation));
      dispatch(borrowerActions.setAddressInformation(addressInformation));
      dispatch(borrowerActions.setDTCFinancialInformation(dtcFinancialInformation));
      dispatch(borrowerActions.setDTMFinancialInformation(dtmFinancialInformation));
      dispatch(borrowerActions.setSocialSecurity(ssnInformation));

      props.onNext();
    }
  }

  return {
    initialValues, validationSchema, loanPurposeOptions, propertyStatusOptions, employmentStatusOptions,
    payFrequencyOptions, creditRatingOptions, educationLevelOptions, handleSubmit, lenderType, setEmploymentStatus,
    displayEmploymentStartDate, displayEmploymentEndDate, merchant
  };
}
import { ENABLE_FINANCING_API_INSTANCE, ENABLE_FINANCING_API_INSTANCE_NO_TOKEN } from './ActionConstants';
import { getStatus } from 'utils/helpers';
import { IAddress, IBusinessInfo, IContact, IFilter, IList } from 'common/interfaces';
import Observer, { EVENTS } from 'classes/Observer';
import ErrorHandler from './ErrorHandler';
import store from 'reducers/Store';
import merchantActions from "reducers/MerchantReducer";
import Log from 'classes/Log';
import RequestHandler from 'classes/RequestHandler';
import ApiCache from 'classes/ApiCache';
import { stripOutNonDigits } from 'utils/formatters';

export const MerchantHandler = (customErrorHandler?: typeof ErrorHandler, triggerEvents: boolean = true) => {
  const errorHandler = customErrorHandler || ErrorHandler;
  /**
  * @description Add merchant - business information.
  * @param {IBusinessInfo} data Business info.
  * @returns {Promise<any>} API response.
  * @emits EVENTS.MERCHANT_CREATED
  */
  const create = async (data: IBusinessInfo): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.post(`customer/merchant/`, data);
      if (triggerEvents) Observer.trigger(EVENTS.MERCHANT_UPDATED);
      return Promise.resolve(response.data);
    } catch (error) {
      errorHandler(error);
      return Promise.resolve(null);
    }
  }

  /**
  * @description Add merchant - address.
  * @param {string} slug Slug.
  * @param {any} data Merchant data.
  * @returns {Promise<any>} API response.
  * @emits EVENTS.MERCHANT_UPDATED
  */
  const patch = async (slug: string, data: any): Promise<any> => {
    try {
      if (data.max_loan_amount) {
        data.max_loan_amount = stripOutNonDigits(data.max_loan_amount);
      }
      if (data.main_contact_phone) {
        data.main_contact_phone = stripOutNonDigits(data.main_contact_phone);
      }
      await ENABLE_FINANCING_API_INSTANCE.patch(`customer/merchant/${slug}/`, data);
      let newSlug = data?.slug;
      if (data instanceof FormData) {
        newSlug = data.get('slug');
      }
      const response = await get(newSlug || slug, false);
      store.dispatch(merchantActions.update(response.data));
      if (triggerEvents) Observer.trigger(EVENTS.MERCHANT_UPDATED);
      return Promise.resolve(response);
    } catch (error) {
      errorHandler(error);
      // we are using the error here to show the toast
      return Promise.reject(error);
    }
  }

  /**
   * @description Add merchant - landing page information.
   * @param {Blob} primary_logo File binary.
   * @param {string} originalSlug Slug.
   * @param {string} newSlug Slug.
   * @param {boolean} activateMerchant Activate merchant?
   * @param {boolean} setMerchantAsPending Set merchant as pending.
   * @returns {Promise<any>} API response.
   * @emits EVENTS.MERCHANT_UPDATED
   */
  const patchWithLogo = async (primary_logo: Blob, originalSlug: string, newSlug: string, activateMerchant?: boolean, setMerchantAsPending?: boolean): Promise<any> => {
    const body = new FormData();
    if (primary_logo) {
      body.append("primary_logo", primary_logo);
    }
    if (newSlug) {
      body.append("slug", newSlug);
    }
    if (setMerchantAsPending) {
      body.append("status", (await getStatus("Pending")).toString());
    } else if (activateMerchant) {
      body.append("status", (await getStatus("Active")).toString());
    }
    if ([...body.entries()].length > 0) {
      return await patch(originalSlug, body);
    } else {
      return Promise.resolve({ slug: newSlug || originalSlug });
    }
  }

  /**
   * @description Update merchant template.
   * @param {Blob} primary_logo File binary.
   * @param {string} originalSlug Slug.
   * @param {string} newSlug Slug.
   * @param {string} color_theme Color theme.
   * @param {string} industry_template Industry template.
   * @param {boolean} activateMerchant Activate merchant?
   * @param {boolean} setMerchantAsPending Set merchant as pending.
   * @returns {Promise<any>} API response.
   * @emits EVENTS.MERCHANT_UPDATED
   */
  const updateTemplate = async (primary_logo: Blob, originalSlug: string, newSlug: string, color_theme: string, industry_template: string, landing_page_program: string, landing_page_loan_amount: number, activateMerchant?: boolean, setMerchantAsPending?: boolean): Promise<any> => {
    const body = new FormData();
    if (primary_logo) { body.append("primary_logo", primary_logo); }
    if (newSlug) { body.append("slug", newSlug); }
    if (color_theme) { body.append("color_theme", color_theme); }
    if (industry_template) { body.append("industry_template", industry_template); }
    if (landing_page_program) { body.append("landing_page_program", landing_page_program); }
    if (landing_page_loan_amount) { body.append("landing_page_loan_amount", landing_page_loan_amount.toString()); }
    if (setMerchantAsPending) { body.append("status", (await getStatus("Pending")).toString()); } else if (activateMerchant) { body.append("status", (await getStatus("Active")).toString()); }

    if ([...body.entries()].length > 0) {
      return await patch(originalSlug, body);
    } else {
      return Promise.resolve({ slug: newSlug || originalSlug });
    }
  }

  /** 
  * @description Get merchant history.
  * @param {string} next Next page.
  * @param {string} slug Merchant slug.
  * @returns {Promise<any>} API response.
  */
  const getHistory = async (next: string, slug: string): Promise<any> => {
    try {
      const url = next || `customer/merchant/${slug}/history/?offset=0&limit=30`;
      const response = await ApiCache.get(url);
      return Promise.resolve({
        ...response,
        originalUrl: url
      } as IList);

    } catch (error) {
      errorHandler(error);
      return Promise.resolve(null);
    }
  }

  /**
  * @description Decline merchant.
  * @param {string} slug Merchant slug.
  * @param {string} reason Reason why the merchant was declined.
  * @returns {Promise<any>} API response.
  */
  const decline = async (slug: string, reason: string): Promise<any> => {
    const activeStatus = await getStatus("Declined");
    return await patch(slug, { status: activeStatus, reason });
  }

  /**
  * @description Approve merchant.
  * @param {string} slug Merchant slug.
  * @returns {Promise<any>} API response.
  */
  const approve = async (slug: string): Promise<any> => {
    const activeStatus = await getStatus("Active");
    return await patch(slug, { status: activeStatus });
  }

  /**
  * @description Deactivate merchant.
  * @param {string} slug Merchant slug.
  * @returns {Promise<any>} API response.
  */
  const deactivate = async (slug: string): Promise<any> => {
    const deactivatedStatus = await getStatus("Deactivated");
    return await patch(slug, { status: deactivatedStatus });
  }

  /**
 * @description Get all of the white label merchants.
 * @param {IFilter} filter Merchant filter.
 * @returns API response.
 */
  const getAll = async (filter: IFilter): Promise<Array<any>> => {
    try {
      let url = `customer/merchant/?offset=0&limit=1000000&simple=true`;
      url = url.Filter(filter);

      const response = await ApiCache.get(url);
      return Promise.resolve(response.results);

    } catch (error) {
      errorHandler(error);
      return Promise.resolve(null);
    }
  }

  /**
   * @description Get merchant details.
   * @param {string} slug Merchant slug.
   * @param {boolean} isAnonymous Should this call ignore the access token?
   * @returns {Promise<any>} API response.
   */
  const get = async (slug: string, isAnonymous: boolean = false): Promise<any> => {
    if (!slug) {
      return Promise.resolve(null);
    }

    try {
      let response: any = null;
      if (!isAnonymous) {
        response = await ENABLE_FINANCING_API_INSTANCE.get(`customer/merchant/${slug}/`);
      } else {
        response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.get(`customer/merchant/${slug}/`);
      }
      store.dispatch(merchantActions.update(response.data));
      return Promise.resolve(response.data);

    } catch (error) {
      if (error?.response?.data?.detail === "Not found.") {
        Log.warn('Merchant was not found');
        return Promise.resolve(null);
      }
      errorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Finalize merchant flow.
   * @param {number} invitation_id Invitation id.
   * @param {string} vuid vuid sent on the invitation email.
   * @param {boolean} isRevision Is revision?
   * @returns {Promise<any>} API response.
   */
  const finalizeMerchantFlow = async (invitation_id: number, vuid: string, isRevision: boolean): Promise<any> => {

    try {
      const response = await RequestHandler.makeRequest(`invitation/whitelabel/merchant-invite/${invitation_id}/finalize?vuid=${vuid}`, isRevision ? 'PATCH' : 'PUT', null, true);
      if (triggerEvents) Observer.trigger(EVENTS.MERCHANT_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      errorHandler(error);
      return Promise.resolve(null);
    }
  }

  /**
  * @description Delete incomplete merchant.
  * @param {string} slug Merchant slug.
  * @returns {Promise<any>} API response.
  */
  const deleteIncompleteMerchant = async (slug: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.delete(`customer/merchant/${slug}/`);
      if (triggerEvents) Observer.trigger(EVENTS.MERCHANT_UPDATED);
      return Promise.resolve(response.data);
    } catch (error) {
      errorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Save business information.
   * @param {IBusinessInfo} business_info Business info.  
   * @param {IAddress} mainAddress Main address.  
   * @param {number} invitation_id Invitation id.
   * @param {string} vuid vuid sent on the invitation email.
   * @returns {Promise<any>} API response.
   */
  const saveBusinessInformation = async (business_info: IBusinessInfo, mainAddress: IAddress, invitation_id: number, vuid: string): Promise<any> => {

    try {
      let body = {
        ...business_info,
        ...mainAddress,
        postal_code: mainAddress.postal_code
      };

      let url = `invitation/whitelabel/merchant-invite/business-info`;
      if (invitation_id && vuid) {
        url = `invitation/whitelabel/merchant-invite/${invitation_id}/business-info?vuid=${vuid}`;
      }

      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.put(url, body);
      if (triggerEvents) Observer.trigger(EVENTS.MERCHANT_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      errorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * @description Save main contact information.
   * @param {IContact} main_contact Main contact information.  
   * @param {number} invitation_id Invitation id.
   * @param {string} vuid vuid sent on the invitation email.
   * @param {boolean} isRevision Is this a revision after a decline?
   * @returns {Promise<any>} API response.
   */
  const saveMainContact = async (main_contact: IContact, invitation_id: number, vuid: string, isRevision: boolean): Promise<any> => {

    try {
      const payload = {
        main_contact_first_name: main_contact.main_contact_first_name,
        main_contact_last_name: main_contact.main_contact_last_name,
        main_contact_phone: main_contact.main_contact_phone
      }
      if (!isRevision) {
        // we cannot change the email when it is a revision.
        payload["main_contact_email"] = main_contact.main_contact_email;
      }
      const response = await RequestHandler.makeRequest(`invitation/whitelabel/merchant-invite/${invitation_id}/main-contact?vuid=${vuid}`, isRevision ? 'PATCH' : 'PUT', payload, true);
      if (triggerEvents) Observer.trigger(EVENTS.MERCHANT_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      errorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Save landing page information.
   * @param {number} invitation_id Invitation id.
   * @param {string} vuid vuid sent on the invitation email. 
   * @param {string} slug Merchant slug.
   * @param {Blob} file Logo file.
   * @param {string} color_theme Color theme.
   * @param {string} industry_template Industry template.
   * @returns {Promise<any>} API response.
   */
  const saveLandingPage = async (invitation_id: number, vuid: string, slug?: string, file?: Blob, color_theme?: string, industry_template?: string): Promise<any> => {
    try {
      const body = new FormData();
      if (slug) { body.append("slug", slug); }
      if (color_theme) { body.append("color_theme", color_theme); }
      if (industry_template) { body.append("industry_template", industry_template); }
      if (file) { body.append("primary_logo", file); }

      if ([...body.entries()].length > 0) {
        const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.put(`invitation/whitelabel/merchant-invite/${invitation_id}/landing-page?vuid=${vuid}`, body);
        if (triggerEvents) Observer.trigger(EVENTS.MERCHANT_UPDATED);
        return Promise.resolve(response.data);
      }
      return Promise.resolve();
    } catch (error) {
      errorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
  * @description Get list of merchants
  * @param {string} next Used to get the next page.
  * @param {IFilter} filter Merchant filter.
  * @param {boolean} preventPagination Prevent breaking the result in different pages.
  * @returns {Promise<IList>} Merchants.
  */
  const getMany = async (next: string, filter: IFilter, preventPagination: boolean = false): Promise<IList> => {
    try {
      let url = next || `customer/merchant/?offset=0&limit=30`;
      url = url.Filter(filter);

      if (preventPagination) {
        url = url.AddQuerystring("offset", "0").AddQuerystring("limit", "1000000");
      }

      const response = await ApiCache.get(url);
      return Promise.resolve({
        ...response,
        originalUrl: url
      } as IList);

    } catch (error) {
      errorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get merchant stats.
   * @param {string} slug Merchant slug.
   * @param {boolean} isAnonymous Should this call ignore the access token?
   * @param {IFilter} filter Merchant filter.
   * @returns {Promise<any>} API response.
   */
  const getStats = async (slug: string, isAnonymous: boolean = false, filter?: IFilter): Promise<any> => {
    if (!slug) {
      return Promise.resolve(null);
    }

    try {
      let response: any = null;
      let url = `dashboards/merchant-metrics/${slug}/`;
      if (filter) {
        url = url.Filter(filter);
      }

      if (!isAnonymous) {
        response = await ApiCache.get(url, false);
      } else {
        response = await ApiCache.get(url, true);
      }
      store.dispatch(merchantActions.update(response));
      return Promise.resolve(response);

    } catch (error) {
      if (error?.response?.data?.detail === "Not found.") {
        Log.warn('Merchant was not found');
        return Promise.resolve(null);
      }
      errorHandler(error);
      return Promise.reject(error.response);
    }
  }

  return {
    create,
    patch,
    patchWithLogo,
    getHistory,
    decline,
    approve,
    deactivate,
    getAll,
    get,
    finalizeMerchantFlow,
    deleteIncompleteMerchant,
    saveBusinessInformation,
    saveMainContact,
    saveLandingPage,
    getMany,
    getStats,
    updateTemplate
  }
}
import React, { useEffect, useState } from "react";
import { HeaderWL, Link } from "common";
import { Input, Form } from "common/form";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "auth/useAuth";
import strings from "localization/Strings";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import appActions from "reducers/AppReducer";
import popUpActions from "reducers/PopUpReducer";
import { POPUPS } from "utils/constants";
import { EmailValidation, Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";
import queryString from "query-string";
import { displayFullscreenLoading, hideFullscreenLoading } from "utils/helpers";
import AuthHandler from "actions/AuthHandler";
import Analytics, { ITracking } from "classes/Analytics";
import LocalStorage from "classes/LocalStorage";
import SystemHandler from "actions/SystemHandler";
import { useIsDtmEnabled } from "hooks/useIsDtmEnabled";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const urlParams = queryString.parse(window.location.search);
  const innerPopupOpened = useAppSelector(state => state.popUp.showInnerPopup);
  const isPopupOpened = useAppSelector(state => state.popUp.showPopup);
  const [profile, setProfile] = useState(null);
  const { isDtmEnabled, ready } = useIsDtmEnabled(profile);

  useEffect(() => {
    LocalStorage.clear(true);
  }, []);

  useEffect(() => {
    if (urlParams["email"]) {
      navigate('/login');
      document.getElementById('login_password_input')?.focus();
    } else {
      document.getElementById('login_email_input')?.focus();
    }
  }, [urlParams]);

  useEffect(() => {
    if (ready && profile) {
      if (isDtmEnabled && profile && !profile?.program_view) {
        LocalStorage.save('show_program_view_popup', true);
      }

      if (profile.status === "DEACTIVATED") {
        navigate('/accountDeactivation');
      } else {
        auth?.login(profile, location.state?.pathname || '/dashboard').then(() => {
          Analytics.track({ experience: "portal_login", screen: "login", object: "login_attempt", action: "successful" } as ITracking);
          // you have to get additional resources after the user logs in
          SystemHandler.getSupportInfo();
        });
      }
      hideFullscreenLoading();
    }
  }, [isDtmEnabled, profile, ready]);

  const handleSubmit = async (data: any) => {
    if (!isPopupOpened && !innerPopupOpened) {
      try {
        displayFullscreenLoading("Logging in");

        dispatch(appActions.clearLocation());
        const profile = await AuthHandler.login(data.email, data.password);

        setProfile(profile);
      } catch (error) {
        hideFullscreenLoading();
        auth?.logout(true);
        return Promise.reject();
      }
    }
  }

  return <>
    <HeaderWL />
    <Form
      id="login"
      buttonPosition="bottom center"
      onFormSubmit={handleSubmit}
      title={strings.pages.login.title}
      submitBtnText={strings.pages.login.title}
      validationSchema={Schema({
        email: EmailValidation,
        password: SimpleRequireValidation
      })}
      values={{
        email: urlParams["email"] || "",
        password: ""
      }}
      inputFields={[
        [
          <Input type="email" name="email" id="login_email" label="Email" />
        ],
        [
          <Input type="password" name="password" id="login_password" label="Password" />
        ]
      ]}
      styleOverride={{
        formContainer: { maxWidth: "45rem" },
        pageContainer: { paddingTop: "12rem" }
      }}
      formHelp={
        <>
          <div>
            <Link
              href="#"
              id="login_help"
              onClick={() => {
                Analytics.track({ experience: "portal_login", screen: "login", object: "help_link", action: "clicked" } as ITracking);
                dispatch(popUpActions.openPopup({ name: POPUPS.HELP, message: "MERCHANT" }));
              }}>
              {strings.common.help}
            </Link>
          </div>
          <div>
            <Link id="login_forgotPassword" href="/forgotPassword">{strings.pages.login.forgotPassword}</Link>
          </div>
        </>
      }
    />
  </>;
};

export default Login;
import * as Yup from "yup";
import strings from "localization/Strings";
import moment from "moment";
import { DEFAULT_MAX_LOAN_AMOUNT, DOMAIN_PREFIX, REGEX_CHECKS, TODAY } from "utils/constants";
import { formatAPICurrency } from "utils/formatters";
import { RequireDateInRangeValidation, RequirePastDateValidation } from "./dateValidation";
import { tryParseNumber } from "utils/helpers";

export const addressMaxChars = 200;
export const emailMaxChars = 255;
export const nameMaxChars = 200;

export const SimpleRequireValidation = Yup.string()
  .required("This is a required field");

export const NameValidation = Yup.string()
  .trim()
  .matches(/^[A-Za-zÀ-ÖØ-öø-ÿĀ-ž]+(?:[-'\s][A-Za-zÀ-ÖØ-öø-ÿĀ-ž]+)*$/gi, "Only alphabets are allowed")
  .min(1, "This is a required field")
  .max(nameMaxChars, "Name cannot contain more than " + nameMaxChars + " characters")
  .required("This is a required field");

export const BusinessNameValidation = Yup.string()
  .trim()
  .min(1, "This is a required field")
  .max(nameMaxChars, "Name cannot contain more than " + nameMaxChars + " characters")
  .required("This is a required field");

export const EmailValidation = Yup
  .string()
  .email(strings.validation.validEmail)
  .test("is-email-valid", strings.validation.validEmail, (value) => {
    if (!value) {
      return true; // Skip validation if no value is provided
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-z]{2,}$/i;
    const validTLDs = ["com", "edu", "mil", "org", "net", "int", "gov", "arpa", "mx", "us", "uk", "fr", "br", "biz", "ca", "io", "academy", "accountant", "accountants", "agency", "attorney", "boutique", "broker", "build", "builders", "business", "center", "clinic", "clothing", "club", "coach", "company", "consulting", "contact", "contractors", "courses", "cpa", "dental", "dentist", "digital", "doctor", "email", "finance", "financial", "fit", "fitness", "global", "graphics", "healthcare", "info", "ink", "insurance", "insure", "international", "law", "lawyer", "legal", "life", "limited", "limo", "loan", "loans", "ltd", "luxury", "management", "market", "marketing", "media", "money", "mortgage", "network", "ninja", "online", "partners", "parts", "photo", "photography", "photos", "pictures", "pro", "productions", "properties", "property", "realtor", "shopping", "site", "social", "solutions", "studio", "style", "surgery", "tax", "tickets", "tires", "tours", "travel", "website", "works", "inc", "ai", "llc", "co", "me", "work", "tv"];

    if (!emailRegex.test(value)) {
      return false; // Invalid email format
    }

    const domain = value.split('@')[1].toLowerCase();
    const domainTLD = domain.split('.').pop();

    return validTLDs.includes(domainTLD);
  })
  .required(strings.validation.requiredField);


export const PhoneValidation = Yup.string()
  .matches(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im, "Please enter a valid phone number.")
  .min(10)
  .required("This is a required field");

export const PasswordValidation = Yup.string()
  .min(8, "Password requires at least 8 characters")
  .matches(REGEX_CHECKS.requireDigit, "Password requires at least one number")
  .matches(REGEX_CHECKS.requireLowercase, "Password requires at least one lowercase letter")
  .matches(REGEX_CHECKS.requireUppercase, "Password requires at least one uppercase letter")
  .matches(REGEX_CHECKS.requireNonAlphanumeric, "Password requires at least one special character")
  .required(strings.validation.requiredField);

export const URLValidation = Yup.string()
  .trim()
  .matches(
    // /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-]+)*(\/?)|(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,// to include optional "/" at the end
    "Enter a valid URL"
  )
  .required("This is a required field");

export const getSSNValidation = (allowAsterisks: boolean) => {
  return Yup.string()
    .test(
      "is-ssn-valid",
      "Invalid Social Security Number",
      (value: any) => {
        // The social security number should be in the format ###-##-####
        const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;

        // The SSN can also be in the format *********, if it is already present in the API
        const ssnRegex2 = "*********";

        // Check if the input string matches the regex pattern
        if (!ssnRegex.test(value) && (!allowAsterisks || value !== ssnRegex2)) {
          return false;
        }

        // Check if the first three digits are not in the invalid range of 000, 666, or between 900-999
        const firstThreeDigits = value.substring(0, 3);
        if (firstThreeDigits === '000' || firstThreeDigits === '666' || (parseInt(firstThreeDigits) >= 900 && parseInt(firstThreeDigits) <= 999)) {
          return false;
        }

        // Check if the middle two digits are not in the invalid range of 00
        const middleTwoDigits = value.substring(4, 6);
        if (middleTwoDigits === '00') {
          return false;
        }

        // If all checks pass, return true
        return true;
      })
    .required("This is a required field");
}

export const getITINValidation = (allowAsterisks: boolean) => {
  return Yup.string()
    .test(
      "is-itin-valid",
      "Invalid Individual Taxpayer Identification Number",
      (value: any) => {
        // The social security number should be in the format ###-##-####
        const itinRegex = /^\d{3}-\d{2}-\d{4}$/;

        // The ITIN can also be in the format *********, if it is already present in the API
        const itinRegex2 = "*********";

        // ITIN
        // always start with 9
        // 4th and 5th digit are in ranges of 70-88, 90-92, or 94-99
        // 9XX-7X-XXXX or 9XX-8X-XXXX or 9XX-9X-XXXX
        const itinRegex3 = /^9\d{2}-(7[0-9]|8[0-8]|9[0-2]|9[4-9])-\d{4}$/;

        // Check if the input string matches the regex pattern
        if ((!itinRegex.test(value) || !itinRegex3.test(value)) && (!allowAsterisks || value !== itinRegex2)) {
          return false;
        }

        // If all checks pass, return true
        return true;
      })
    .required("This is a required field");
}

export const getSSNorITINvalidation = (allowAsterisks: boolean) => {
  return Yup.string()
    .test('is-valid-ssn', 'Invalid SSN / ITIN', (value) => {
      return getSSNValidation(allowAsterisks).isValidSync(value) || getITINValidation(allowAsterisks).isValidSync(value);
    })
    .required("This is a required field");
}

export const getEINValidation = (allowAsterisks: boolean) => {
  return Yup.string()
    .test(
      "is-ein-valid",
      "Invalid Employer Identification Number",
      (value: any) => {
        // The employer identification number should be in the format ##-#######
        const einRegex = /^\d{2}-\d{7}$/;

        // The employer identification number can also be in the format *********, if it is already present in the API
        const einRegex2 = "*********";

        // Check if the input string matches the regex pattern
        if (!einRegex.test(value) && (!allowAsterisks || value !== einRegex2)) {
          return false;
        }

        // Check if the first two digits are not in the invalid range of 00
        const firstTwoDigits = value.substring(0, 2);
        if (firstTwoDigits === '00') {
          return false;
        }

        // If all checks pass, return true
        return true;
      })
    .required("This is a required field");
}

export const getBusinessCheckingAccountValidation = (allowAsterisks: boolean) => {
  return Yup.string()
    .test(
      "is-business-checking-account-number-valid",
      "Invalid Business Checking Account Number",
      (value: any) => {
        // The employer identification number should allow only 1-17 digits
        const bankAccountRegex = /^\d{1,17}$/;

        // The employer identification number can also allow 1-17 asterisks (*)
        const bankAccountRegex2 = /^[\*]{1,17}$/;

        // Check if the input string matches the regex pattern
        if (!bankAccountRegex.test(value) && (!allowAsterisks || !bankAccountRegex2.test(value))) {
          return false;
        }

        // If all checks pass, return true
        return true;
      })
    .required("This is a required field");
}

export const BusinessCheckingRoutingValidation = Yup.string()
  .test(
    "is-business-checking-routing-valid",
    "Invalid Business Checking Routing Number",
    (value: any) => {
      // The employer identification number should allow only exactly 9 digits
      const bankRoutingRegex = /^\d{9}$/;

      // Check if the input string matches the regex pattern
      if (!bankRoutingRegex.test(value)) {
        return false;
      }

      // If all checks pass, return true
      return true;
    })
  .required("This is a required field");

export const AnnualIncomeValidation = Yup.string()
  .matches(/^[0-9,.]*$/, "Annual income must be a valid US amount. Example 50,000")
  // .test("len", "Annual income must be at least $1000", (val: any) =>
  //   val ? Number(val.replace(/[^\d]/g, "")) >= 1000 : true
  // )
  .required("This is a required field");

export const LoanAmountValidation = (maxLoanAmount: number = DEFAULT_MAX_LOAN_AMOUNT) =>
  Yup.string()
    .matches(/^[0-9,$.]*$/, "Must be a valid US amount. Example 5,000")
    .test("len", `Loan amount should be between $1,000 and ${formatAPICurrency(maxLoanAmount, false)}`, (val: any) => {
      const numericValue = Number(val.replace(/[^\d]/g, ""));
      return val ? (numericValue >= 1000 && numericValue <= maxLoanAmount) : true;
    })
    .required("This is a required field");

export const PersonalInfoValidation = {
  first_name: Yup.string()
    .trim()
    .min(1, "First name must be at least 1 characters")
    .max(nameMaxChars, "First name cannot contain more than " + nameMaxChars + " characters")
    .required("This is a required field")
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿĀ-ž]+(?:[-'\s][A-Za-zÀ-ÖØ-öø-ÿĀ-ž]+)*$/gi, "Only alphabets are allowed"),
  last_name: Yup.string()
    .min(1, "Last name must be at least 1 characters")
    .max(nameMaxChars, "Last name cannot contain more than " + nameMaxChars + " characters")
    .trim()
    .required("This is a required field")
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿĀ-ž]+(?:[-'\s][A-Za-zÀ-ÖØ-öø-ÿĀ-ž]+)*$/gi, "Only alphabets are allowed"),
  date_of_birth: RequirePastDateValidation
}

export const AddressValidation = {
  address1: Yup.string()
    .trim()
    .min(1, "This is a required field")
    .max(addressMaxChars, "Address line cannot contain more than " + addressMaxChars + " characters")
    .required("This is a required field"),
  city: Yup.string()
    .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.`\u00B4']*)$/gi, "Only alphabets are allowed")
    .min(1, "This is a required field")
    .max(addressMaxChars, "City cannot contain more than " + addressMaxChars + " characters")
    .required("This is a required field"),
  state: Yup.string()
    .required("State is a required field"),
  postal_code: Yup.string()
    .matches(/^\d{5}$/, "Postal code must contain 5 digits")
    .required("This is a required field")

}

export const LocationValidation = {
  ...AddressValidation,
  location_name: Yup.string()
    .trim()
    .min(1, "Location name needs to be more than 1 character.")
    .required("This is a required field")
}

export const LocationValidation2 = {
  ...AddressValidation,
  name: Yup.string()
    .trim()
    .min(1, "Location name needs to be more than 1 character.")
    .required("This is a required field")
}

export const BusinessValidation = {
  name: BusinessNameValidation,
  website: SimpleRequireValidation,
}

export const ContactValidation = {
  main_contact_first_name: NameValidation,
  main_contact_last_name: NameValidation,
  main_contact_email: EmailValidation,
  main_contact_phone: PhoneValidation
}

export const MainContactValidation = {
  main_contact_first_name: NameValidation,
  main_contact_last_name: NameValidation,
  main_contact_email: EmailValidation,
  main_contact_phone: PhoneValidation
}

export const LandingPageValidation = {
  domain: Yup.string()
    .test("domain", "Landing page URL is required", (val: string) => {
      const slug = val?.replace(DOMAIN_PREFIX, "");
      return slug ? true : false
    }
    ),
  primary_logo: Yup.mixed()
    .required("Please upload an image"),
}

export const Schema = (schema: any) => {
  return Yup.object(schema);
};

export const EitherEmailOrPhoneValidation = {
  email: EmailValidation
    .notRequired()
    .test(
      "only-email-is-filled-out",
      strings.validation.exactlyOneFieldRequired,
      (value, context) => {
        if ((value && !context?.parent?.phone_number)
          || (!value && context?.parent?.phone_number)) {
          return true;
        }
        return false;
      }),
  phone_number: PhoneValidation
    .notRequired()
    .test(
      "only-phone-is-filled-out",
      strings.validation.exactlyOneFieldRequired,
      (value, context) => {
        if ((value && !context?.parent?.email)
          || (!value && context?.parent?.email)) {
          return true;
        }
        return false;
      }),
}

export const URLFriendlyText = Yup.string()
  .matches(/^[A-Za-z0-9]+(?:[-_][A-Za-z0-9]+)*$/gi, "Only letters, numbers, and the symbols - and _ are allowed")
  .min(6, "URL needs to be more than 6 characters.")
  .required("This is a required field");

export const MaxLoanAmountValidation = Yup.string()
  .matches(/^[0-9,.]*$/, "Must be a valid US amount. Example 5,000")
  .test("len", "Max loan amount must be between $1,000 and $100,000", (val: any) =>
    val ? Number(val.replace(/[^\d]/g, "")) >= 1000 && Number(val.replace(/[^\d]/g, "")) <= DEFAULT_MAX_LOAN_AMOUNT : true
  )
  .required("This is a required field");

export const LastFourSSNValidation = Yup.string()
  .min(4, "Min of 4 digits")
  .required("This is a required field");

export const OneOrMoreValidation = Yup.string()
  .matches(/^[0-9,.]*$/, "Must be a valid US amount. Example 500")
  .test("len", "Amount must be at least 1", (val: any) =>
    val ? Number(val.replace(/[^\d]/g, "")) >= 1 : true
  )
  .required("This is a required field");

export const DownPaymentValidation = {
  down_payment_amount: OneOrMoreValidation,
  first_payment_date: RequireDateInRangeValidation(TODAY, moment().add(30, 'days').toDate())
};

export const rangeValidation = (minValue: number, maxValue: number) =>
  Yup.string()
    .matches(/^\d*$/, "Must be a valid number")
    .test("range", `Number should be between ${minValue} and ${maxValue}`, (val: string) => {
      const numericValue = tryParseNumber(val, true);
      return val ? (numericValue >= minValue && numericValue <= maxValue) : true;
    })
    .required("This is a required field");

export const PercentValidation = Yup.number()
  .typeError("Value must be a number")
  .test("range", "Percentages must be between 0 and 100", (val: any) => {
    return val ? val >= 0 && val <= 100 : true
  }
  )
  .required("This is a required field");

export const AnnualSalesValidation = Yup.string()
  .test("size", "Must be at least $1.2M", (val: any) =>
    val ? Number(val.replace(/[^\d]/g, "")) >= 1200000 : true
  )
  .required("This is a required field");

// TODO: check if we really need a requested loan validation without max loan amount?
export const RequestedLoanAmountValidation = Yup.string()
  .matches(/^[0-9,.]*$/, "Must be a valid US amount. Example 5,000")
  .test("len", "Requested loan amount must be at least $1000", (val: any) =>
    val ? Number(val.replace(/[^\d]/g, "")) >= 1000 : true
  )
  .required("This is a required field");

export const RequestedLoanAmountValidation2 = Yup.string()
  .matches(/^[0-9,.]*$/, "Must be a valid US amount. Example 5,000")
  .test("len", "Requested loan amount must be between $1,000 and $25,000", (val: any) =>
    val ? (Number(val.replace(/[^\d]/g, "")) >= 1000 && Number(val.replace(/[^\d]/g, "")) <= 25000) : true
  )
  .required("This is a required field");

export const getRequestRefundAmountValidation = (fundedAmount: number) => {
  return Yup.string()
    .matches(/^[0-9,.]*$/, "Must be a valid US amount. Example 5,000")
    .test("len", "Refund amount must be less than funded amount", (val: any) =>
      val ? Number(val.replace(/[^\d]/g, "")) <= fundedAmount : true
    )
    .required("This is a required field");
}

export const EstimatedSalesValidation = {
  annual_sales: SimpleRequireValidation,
  monthly_financing_volume: SimpleRequireValidation,
  average_ticket: SimpleRequireValidation
}

export const SlugValidation = Yup.string()
  .matches(/^[A-Za-z0-9]+(?:[-_][A-Za-z0-9]+)*$/gi, "Only letters, numbers, and the symbols - and _ are allowed")
  .min(6, "URL needs to be more than 6 characters.")
  .required("This is a required field")
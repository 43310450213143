// used in WL settings - new view
import Landing from "common/Landing";
import React from "react";
import { useAppSelector } from "reducers/Hooks";
import { WHITELABEL_LANDING_PAGE_TEMPLATE } from "utils/constants";

const LandingPageContent: React.FC = () => {
  const whitelabel = useAppSelector(state => state.whitelabel);

  return <>
    <div className="logoPreview" style={{ border: "1px solid var(--primaryColor20Solid)" }}>
      <Landing
        id="landingPreview"
        title={`Give your customers the power of choice with ${whitelabel?.name}`}
        subtitle={`Don't let a lack of financing options hold your business back! We provide access to over 20 lenders to help you convert more customers.`}
        hero="/assets/images/whitelabel_default.png"
        type="WHITELABEL"
        preview={true}
        showOnlyAboveFold={false}
        merchantLogoPreview={null}
        merchantInitials={null}
        {...WHITELABEL_LANDING_PAGE_TEMPLATE}
      />
    </div>
  </>;
}

export default LandingPageContent;
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./BorrowerExperience.module.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  AddressInformation, Agreement, ContactInformation, DTCFinancialInformation,
  DTMFinancialInformation,
  LoanInformation, DTCPersonalInformation, DTMPersonalInformation, SocialSecurity, StartApplication
} from "./forms";
import {
  ToolTip, Stepper, HeaderWL, WhiteFooter, HelpButton, ResizeDetection,
  ReCaptchaDisclaimer
} from "common";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { getFlowStep, isSmallScreen } from "utils/helpers";
import { IStepper } from "common/interfaces";
import { useNavigate, useLocation } from "react-router-dom";
import LocalStorage from "classes/LocalStorage";
import useMerchant from "hooks/useMerchant";
import strings from "localization/Strings";
import Analytics, { ITracking } from "classes/Analytics";
import appActions from "reducers/AppReducer";
import Confirmation from "./forms/Confirmation";
import { APPLICATION_SOURCE } from "utils/constants";
import useGetLoanApplication from "hooks/useGetLoanApplication";

const Borrower: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isWidget = useAppSelector(state => state.app.isWidget);
  const whitelabel = useAppSelector(state => state.whitelabel);
  const merchant = useMerchant();
  const borrower = useAppSelector(state => state.borrower);
  const [steps, setSteps] = useState(null);
  let vuid = LocalStorage.get<string>("vuid");

  /* if borrower refreshes in the middle of the application, you have to first load the application to get the source and lender type
  only then can you load the correct steps and send borrower back to the most recent page */
  useGetLoanApplication();

  useEffect(() => {
    if (isWidget) {
      const style = document.documentElement.style;
      style.setProperty('--bgColor', '#FFFFFF');
    }
  }, [isWidget]);

  useEffect(() => {
    // when borrower lands here from invite link, we need to wait for a merchant to be loaded
    if (merchant?.slug && steps) {
      const stepTemp = getFlowStep(steps, 3);
      if (stepTemp === 0) {
        goTo(steps[stepTemp].props.path);
      }
      setStep(stepTemp);
    }
  }, [merchant?.slug, steps]);

  const DTC_STEPS: Array<ReactElement<IStepper>> = useMemo(() => [
    <StartApplication path="StartApplication" screen="application_start_application" />,
    <LoanInformation path="LoanInformation" screen="application_loan_info" />,
    <DTCPersonalInformation path="PersonalInformation" screen="application_personal_info" />,
    <ContactInformation path="ContactInformation" screen="application_contact_info" />,
    <AddressInformation path="AddressInformation" screen="application_address_info" />,
    <DTCFinancialInformation path="FinancialInformation" screen="application_financial_info" />,
    <SocialSecurity path="SocialSecurity" screen="application_ssn" />,
    <Confirmation path="Confirmation" screen="application_confirmation" />,
    <Agreement path="Agreement" screen="application_agreements" />
  ], []);

  const DTM_STEPS: Array<ReactElement<IStepper>> = useMemo(() => [
    <StartApplication path="StartApplication" screen="application_start_application" />,
    ...(borrower.source === APPLICATION_SOURCE.INVITATION || borrower.source === "PHONE" ? [<LoanInformation path="LoanInformation" screen="application_loan_info" />] : []),
    <DTMPersonalInformation path="PersonalInformation" screen="application_personal_info" />,
    <ContactInformation path="ContactInformation" screen="application_contact_info" />,
    <AddressInformation path="AddressInformation" screen="application_address_info" />,
    // <DTMFinancialInformation path="FinancialInformation" screen="application_financial_info" />,
    <SocialSecurity path="SocialSecurity" screen="application_ssn" />,
    <Confirmation path="Confirmation" screen="application_confirmation" />,
    <Agreement path="Agreement" screen="application_agreements" />
  ], [borrower.source]);

  // set steps (DTC / DTM) based on application source
  useEffect(() => {
    if (borrower.source === APPLICATION_SOURCE.LANDING) {
      if (merchant?.landing_page_program) {
        setSteps(merchant?.landing_page_program === "DTM" ? DTM_STEPS : DTC_STEPS);
      }
    } else if (borrower && (borrower.source === APPLICATION_SOURCE.INVITATION || borrower.source === "PHONE")) {
      if (borrower.lender_type) {
        setSteps(borrower.lender_type === "DTM" ? DTM_STEPS : DTC_STEPS);
      }
    } else if (isWidget) {
      setSteps(DTC_STEPS);
    }
  }, [borrower]);

  const totalStepsCount = useMemo(() => {
    if (steps) {
      return steps.length;
    }
  }, [steps]);

  const [step, setStep] = React.useState<number>(null);
  const [progressStatus, setProgressStatus] = React.useState<number>(100 / totalStepsCount);

  const applicationLocked: boolean = LocalStorage.get<boolean>("application_locked");
  const locationId: string = LocalStorage.get<any>("selected_location")?.id;
  let applicationId = LocalStorage.get<number>("application_id");

  const stepHandler = (moveNext: Boolean) => {
    let newStep = moveNext ? Math.min(step + 1, totalStepsCount - 1) : Math.max(step - 1, 0);
    if (!moveNext) {
      let screen = newStep < steps.length ? steps[newStep].props.screen : "";
      if (screen) {
        Analytics.track({ experience: "borrower", screen: screen, object: "back_arrow", action: "clicked" } as ITracking, null);
      }
    }
    setStep(newStep);
    goTo(steps[newStep].props.path);
  };

  useEffect(() => {
    if (steps) {
      setStep(getFlowStep(steps, 3));
    }
  }, [location, steps]);

  useEffect(() => {
    if (step) {
      setProgressStatus((step + 1) * 100 / totalStepsCount);
    }
  }, [step]);

  const goTo = (path: string) => {
    // so the data is not null when calling onNext in StartApplication, since StartApplication sets vuid and ...
    // ... applicationId - without this, StartApplication onNext() would go back to locationBorrower
    if (path == steps[1].props.path) {
      vuid = LocalStorage.get<string>("vuid");
      applicationId = LocalStorage.get<number>("application_id");
    }

    // prevent going to specific borrower screen by typing url - show loan information screen only if location is selected,
    // show other screens only if application is in progress
    if (((path === steps[0].props.path || isWidget) && locationId) || (applicationId && vuid)) {
      if (merchant?.slug) {
        const pathname = window.location.pathname;
        const newPath = `/${merchant?.slug}/borrowerExperience/${path}`;

        if (newPath != pathname) {
          navigate(newPath);
          if (path === steps[0].props.path && applicationLocked) {
            navigate("/Offers");
          } else {
            dispatch(appActions.addVisitedStep(path));
          }
        }
      }
    } else {
      navigate(`/${merchant?.slug || window.localStorage.getItem('merchant-slug')}/location`);
    }
  }

  const track = (object: string, action: "hover" | "clicked") => {
    Analytics.track({ experience: "borrower", screen: steps[step].props.screen, object, action } as ITracking, null);
  }

  return (
    // step can be 0 though
    steps && step !== null && <ResizeDetection onOrientationChange={() => {
      document.body.click();
    }}>
      <HeaderWL instructions="Fill out the information and we'll match you with offers from our network of lenders!" />
      <main className={styles.borrowerExperienceContainer}>
        <Container fluid className={styles.helpContainer}>
          <Row>
            <Col className={styles.helpCol1}>
              <HelpButton type="BORROWER" onClick={() => track("help_link", "clicked")} />
            </Col>
            <Col className={styles.helpCol2}>
              <ToolTip
                textToolTip="Advertiser Disclosure"
                text={strings.advertiserDisclosure.replaceAll("{whitelabelName}", whitelabel?.name)}
                placement="bottom"
                onHover={() => { track("advertiser_discl_link", "hover") }}
              />
            </Col>
          </Row>
        </Container>
        <div className={styles.invitationFormContainer} style={{ paddingTop: isSmallScreen() ? "3rem" : 0 }}>
          <Stepper steps={steps} step={step} onNext={() => stepHandler(true)} onBack={() => stepHandler(false)} />
        </div>
        {step < totalStepsCount && (
          <ProgressBar
            now={progressStatus}
            className={styles.progressBar}
            style={{
              height: "3rem",
              borderRadius: "0",
            }}
          />
        )}
      </main>
      <WhiteFooter
        onPartnerGuaranteeDisclosureHover={() => track("partner_guarantee_discl_link", "hover")}
        onPrivacyPolicyClick={() => track("privacy_policy_link", "clicked")}
        onRepresentativeExampleHover={() => track("rep_example_discl_link", "hover")}
        onTermsOfServiceClick={() => track("terms_service_link", "clicked")} />
      <ReCaptchaDisclaimer type="INVITATION_FLOWS" />
    </ResizeDetection>
  );
};

export default Borrower;
import styles from "./DashboardHeader.module.scss";
import { CardTick, Location, Logout } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import { useAuth } from "auth/useAuth";
import { AppIcon, Switch } from "common";
import { POPUPS, PROGRAM_VIEWS } from "utils/constants";
import LoadingBar from "./LoadingBar";
import Analytics, { ITracking } from "classes/Analytics";
import { formatFullname } from "utils/formatters";
import { useBusinessModel } from "hooks/useBusinessModel";
import { BusinessModelType } from "common/types";
import LocalStorage from "classes/LocalStorage";
import Observer, { EVENTS } from "classes/Observer";
import useMerchant from "hooks/useMerchant";
import { IUser } from "common/interfaces";

const DashboardHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedLocation = useAppSelector(state => state.app.selectedLocation);
  const selectedBusinessModel = useBusinessModel();
  const merchant = useMerchant();
  const whitelabel = useAppSelector(state => state.whitelabel);

  const auth = useAuth();
  const [fullname, setFullname] = useState("");
  const user = LocalStorage.get<IUser>("user");

  useEffect(() => {
    setFullname(formatFullname(auth?.user?.first_name, auth?.user?.last_name));
  }, [auth]);

  const handleChange = (e: any) => {
    if (e.target.checked) {
      changeBusinessModel("DTC");
    } else {
      changeBusinessModel("DTM");
    }
  };

  const changeBusinessModel = (businessModel: BusinessModelType) => {
    if (selectedBusinessModel !== businessModel) {
      LocalStorage.save('business_model', businessModel);
      Analytics.track({ experience: "portal", screen: "business_model_change", object: "business_model", action: "changed" } as ITracking, { business_model: businessModel });
      Observer.trigger(EVENTS.BUSINESS_MODEL_UPDATED);
    }
  }

  const multiplePrograms = (): boolean => {
    if (user?.user_type === "MERCHANT") {
      return merchant?.programs?.includes("DTM") && merchant?.programs?.includes("DTC");
    } else {
      // WLs and partners
      return whitelabel?.programs?.includes("DTM") && whitelabel?.programs?.includes("DTC");
    }
  }

  return <>
    <div className={styles.dashHeaderContainer}>
      <p className={styles.dashUserBlock}>
        {`Howdy${fullname ? `, ${fullname}` : "user"}!`}
      </p>
      <div className={styles.dashItems}>
        <div className={styles.dashSearchBlock}>
          {/* business model */}
          <div
            className={styles.businessModelBlock}
            id="dashboardHeader_businessModel"
          >
            {/* show only "DTC" or "DTM" */}
            {(!multiplePrograms() || user?.program_view === PROGRAM_VIEWS.HIDE_DTC || user?.program_view === PROGRAM_VIEWS.HIDE_DTM) && <>
              <AppIcon color="var(--whiteTextColor)" icon={CardTick} />
              <p>{selectedBusinessModel || "All"}</p>
            </>}
            {/* show "DTM" - "DTC" toggle */}
            {multiplePrograms() && !(user?.program_view === PROGRAM_VIEWS.HIDE_DTC || user?.program_view === PROGRAM_VIEWS.HIDE_DTM) && <>
              <p>DTM</p>
              <Switch id="dashboardHeader_businessModel" checked={selectedBusinessModel === "DTC"} onChange={handleChange} index={0} alwaysOn={true} backgroundColor={"var(--whiteColor)"} />
              <p>DTC</p>
            </>}
          </div>

          {/* location block */}
          {auth?.user?.user_type === "MERCHANT" && <div
            className={styles.locationModelBlock}
            onClick={() => {
              Analytics.track({ experience: "portal", screen: "location_change", object: "menu", action: "clicked" } as ITracking);
              dispatch(popUpActions.openPopup(POPUPS.LOCATION));
            }}>
            <AppIcon color="var(--whiteTextColor)" icon={Location} />
            <p>{selectedLocation ? selectedLocation.name : "All"}</p>
          </div>}
        </div>

        {/* logout */}
        <div className={styles.dashNotificationBlock}>
          <AppIcon
            clickTrigger={{
              id: "dashboardHeader_logout", onClick: () => auth.logout(true)
            }}
            color="var(--whiteTextColor)"
            size={28}
            icon={Logout}
          />
        </div>

      </div>
      <LoadingBar />
    </div>
    <div style={{ marginBottom: 90 }}></div>
  </>;
};

export default DashboardHeader;

import React, { useEffect, useState } from "react";
import styles from "./InformationCard.module.scss";
import { SmsEdit, Call, Location, DollarCircle, MessageNotif, Shop, UserEdit } from "iconsax-react";
import { Button, LineWithIcon } from "common";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import { useParams } from "react-router-dom";
import SystemHandler from "actions/SystemHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { concatenateAddress, displayMiniFeedback } from "utils/helpers";
import { IAddress } from "common/interfaces";
import _ from "lodash";
import { useAppDispatch } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import { APP_STATUSES, POPUPS } from "utils/constants";
import { formatFullname, formatLenderTypeAndSource, formatPhone } from "utils/formatters";
import Observer, { EVENTS } from "classes/Observer";
import InvitationHandler from "actions/InvitationHandler";
import { useCooldown } from "hooks/useCooldown";
import { useAuth } from "auth/useAuth";

const ApplicationInformationCard: React.FC = () => {
  const [details, setDetails] = useState(null);
  const [loanPurposeOptions, setLoanPurposeOptions] = useState([]);
  const { applicantId } = useParams();
  const { countdown, lastSent, setCooldown, canResend } = useCooldown(0.5);
  const user = useAuth()?.user;

  const observerLoanAppUpdated = Observer.useObserver(EVENTS.LOAN_APP_UPDATED);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (applicantId) {
      SystemHandler.getLoanPurposeOptions()
        .then(options => {
          setLoanPurposeOptions(options);
          getApplication();
        });
    }
  }, [applicantId, observerLoanAppUpdated]);

  const getApplication = () => {
    LoanApplicationHandler.get(applicantId)
      .then(response => {
        setDetails(response);
        if (response?.offers_email_last_sent_at) {
          setCooldown(response?.offers_email_last_sent_at);
        }
      });
  }

  const resendLinkEmail = () => {
    if (canResend()) {
      setCooldown();// you want to set it as soon as the button is clicked, not after email is sent like before
      InvitationHandler.resendApplicationInvite(Number(applicantId))
        .then(() => {
          Analytics.track({ experience: "portal", screen: "view_application_account_info", object: "link_email_resend", action: "successful" } as ITracking, { merchant_id: details?.merchant?.id });
          displayMiniFeedback('Email re-sent successfully');
        });
    }
  }

  const resendOffersEmail = () => {
    if (canResend()) {
      setCooldown();// you want to set it as soon as the button is clicked, not after email is sent like before
      LoanApplicationHandler.resendOffersEmail(applicantId)
        .then(() => {
          Analytics.track({ experience: "portal", screen: "view_application_account_info", object: "offer_email_resend", action: "successful" } as ITracking, { merchant_id: details?.merchant?.id });
          displayMiniFeedback('Email re-sent successfully');
        });
    }
  }

  const address: IAddress = {
    address1: details?.address1,
    city: details?.city,
    state: details?.state,
    postal_code: details?.postal_code,
  };

  const handleHistoryClick = () => {
    dispatch(popUpActions.openPopup(POPUPS.APPLICATION_HISTORY));
  }

  // APP_STATUSES.APPLICATION_SUBMITTED is just temporary status - applciation should move to either offered or no_offers in a split second
  const displayResendLinkEmail = !details?.offered_at && user?.user_type === "MERCHANT" && details?.email && [APP_STATUSES.APPLICATION_STARTED, APP_STATUSES.INVITATION_CLICKED, APP_STATUSES.INVITED].includes(details?.status);
  const displayResendOfferEmail = details?.offered_at && [APP_STATUSES.OFFERED, APP_STATUSES.CLICKED].includes(details?.status);

  return <>
    <div className={styles.cardsWrapper2}>
      <div className={styles.cardsWrapper}>
        <div className={styles.card}>
          <h2 style={{ color: "var(--primaryVariationTextColor)" }}>{formatFullname(details?.first_name, details?.last_name)}</h2>

          <LineWithIcon icon={SmsEdit} text={details?.email} />
          <LineWithIcon icon={Call} text={formatPhone(details?.phone_number)} />
          <LineWithIcon icon={Location} text={concatenateAddress(address)} />
          <LineWithIcon icon={MessageNotif} text={formatLenderTypeAndSource(details?.lender_type, details?.source)} />

          <div className={styles.buttonsWrapper}>
            <div className={styles.buttons} style={{ left: displayResendLinkEmail || displayResendOfferEmail ? 45 : 145 }}>
              <Button
                id="btn_history"
                label="History"
                style={{ width: 160, height: 48, fontSize: 14, fontWeight: 600 }}
                onClick={handleHistoryClick}
              />

              {displayResendLinkEmail &&
                <div className={styles.resendOffers}>
                  <Button
                    id="resend_link_emails"
                    label="Resend link"
                    style={{ width: 160, height: 48, fontSize: 14, fontWeight: 600 }}
                    disabled={false}
                    onClick={resendLinkEmail}
                  />
                </div>
              }

              {displayResendOfferEmail &&
                <div className={styles.resendOffers}>
                  <Button
                    id="resend_offers_emails"
                    label="Resend offers"
                    style={{ width: 160, height: 48, fontSize: 14, fontWeight: 600 }}
                    disabled={false}
                    onClick={resendOffersEmail}
                  />
                  {/* <p style={{
                    textAlign: "right",
                    marginTop: 10,
                    marginBottom: -20,
                    fontSize: 11
                  }}>
                    {countdown && <>{`Please wait ${countdown} to retry`}<br /></>}
                    {lastSent && <>{`Last email: ${lastSent}`}</>}
                  </p> */}
                </div>
              }
            </div>
          </div>
          {/* </>} */}
        </div>

        <div className={styles.card2}>
          <h2 style={{ color: "var(--primaryVariationTextColor)" }}>Applicant Associations</h2>
          <LineWithIcon icon={Shop} text={details?.merchant?.name} />
          <LineWithIcon icon={UserEdit} text={formatFullname(details?.created_by?.first_name, details?.created_by?.last_name)} />
          <LineWithIcon icon={Location} text={details?.location?.name} />
        </div>
      </div>
    </div>
  </>;
}

export default ApplicationInformationCard;
import { Confetti, CloseIcon, Padding } from "common";
import { Input, Form } from "common/form";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup, ICreateUser } from "common/interfaces";
import Popup from "./Popup";
import { ContactValidation, EmailValidation, Schema } from "utils/validation/additionalValidation";
import { isForMerchantGlobal, isForPartnerGlobal, isNullOrUndefinedOrEmpty } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { useAuth } from "auth/useAuth";
import { FormRef } from "common/form/Form";
import { useParams } from "react-router-dom";
import UserHandler from "actions/UserHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { formatFullname } from "utils/formatters";
import useMerchant from "hooks/useMerchant";
import usePartner from "hooks/usePartner";

const InviteUserPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch()
  const { merchantSlug, partnerSlug } = useParams();
  const user = useAuth()?.user;
  const whitelabel = useAppSelector(state => state.whitelabel);
  const merchant = useMerchant();
  const partner = usePartner();
  const formRef = useRef<FormRef>(null);

  const [userCreated, setUserCreated] = React.useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    return () => {
      setUserCreated(false);
      setName('');
    };
  }, []);

  const handleClose = () => {
    setUserCreated(false);
    setName('');
    dispatch(popUpActions.closePopup());
  }

  const handleSubmit = async (data: any) => {
    let createUserBody: ICreateUser = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      group: isForMerchant() ? "Merchant Admin" : (isForPartner() ? "Partner Admin" : "White Label Admin")
    };

    // if inviting another user under the same merchant or partner, you must not include the merchant / partner field
    if (isForMerchant() && user?.user_type !== "MERCHANT") {
      createUserBody.merchant = merchant?.id;
    } else if (isForPartner() && user?.user_type !== "PARTNER") {
      createUserBody.partner = partner?.id;
    }

    setName(formatFullname(createUserBody?.first_name, createUserBody?.last_name));

    await UserHandler.inviteUser(createUserBody);

    Analytics.track({ experience: "portal", screen: user?.user_type === "WHITELABEL" && !isNullOrUndefinedOrEmpty(merchantSlug) ? "merchants_users" : (user?.user_type === "WHITELABEL" && !isNullOrUndefinedOrEmpty(partnerSlug) ? "partners_users" : "settings_users"), object: "user_invite", action: "successful" } as ITracking);

    setUserCreated(true);
  }

  const isForMerchant = () => {
    return isForMerchantGlobal(user, merchantSlug);
  }

  const isForPartner = () => {
    return isForPartnerGlobal(user, partnerSlug, merchantSlug);
  }

  return (
    <Popup isInner={true} showConfetti={userCreated} styleOverride={{ padding: "1.5rem 3.5rem .5rem 3.5rem", gap: "0" }} >
      <>
        {!userCreated && (
          <>
            <CloseIcon id="inviteUserPopup" color="dark" onClose={handleClose} />
            <Form
              id="inviteUserPopup"
              buttonPosition="side right"
              onFormSubmit={handleSubmit}
              title={`${isForMerchant() ? merchant?.name : (isForPartner() ? partner?.name : whitelabel?.name)} • Invite new user`}
              submitBtnText="Send"
              validationSchema={Schema({
                first_name: ContactValidation.main_contact_first_name,
                last_name: ContactValidation.main_contact_last_name,
                email: EmailValidation,
              })}
              values={{
                name: "",
                email: ""
              }}
              inputFields={[
                [
                  <Input type="text" name="first_name" id="inviteUserPopup_contact_first_name" label="Contact first name" />,
                  <Input type="text" name="last_name" id="inviteUserPopup_contact_last_name" label="Contact last name" />,
                ],
                [
                  <Input type="email" name="email" id="inviteUserPopup_email" label="Email" />,
                ]
              ]}
              isInsidePopup
              useSmallerTitle
              ref={formRef}
            />
          </>
        )}
        {userCreated &&
          <Padding all="35px">
            <Confetti
              isPopup
              buttonText="Back to dashboard"
              buttonAction={handleClose}>
              <>
                You have successfully invited
                <br />
                a new user: {name}
              </>
            </Confetti>
          </Padding>
        }
      </>
    </Popup>
  );
};

export default InviteUserPopup;

import { CloseIcon, Scrollable } from "common";
import { Input, SelectDropdown, Form } from "common/form";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import { statesArray } from "utils/constants";
import {
  AddressValidation, BusinessValidation, EmailValidation, NameValidation,
  PhoneValidation, Schema
} from "utils/validation/additionalValidation";
import {
  fillInitialValues, displayMiniFeedback,
  isNotNullNorUndefined
} from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { useAuth } from "auth/useAuth";
import WhiteLabelHandler from "actions/WhiteLabelHandler";
import Analytics, { ITracking } from "classes/Analytics";
import _ from "lodash";
import Popup from "./Popup";

const EditWhitelabelBusinessMainContactPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const user = useAuth()?.user;
  const whitelabel = useAppSelector(state => state.whitelabel);
  const [initialValues, setInitialValues] = useState(null);
  const [inputFields, setInputFields] = useState(null);
  const [validationSchema, setValidationSchema] = useState(null);
  const [buttonToTheRight, setButtonToTheRight] = useState(false);
  const userType = useMemo(() => user?.user_type, [user]);

  // blank initial values
  const whitelabelInitialValues = {
    id: "",
    name: "",
    address1: "",
    city: "",
    postal_code: "",
    state: "",
    main_contact_first_name: "",
    main_contact_last_name: "",
    main_contact_email: "",
    main_contact_phone: ""
  };

  useEffect(() => {
    if (whitelabel?.id) {
      setInitialValues(fillInitialValues(whitelabelInitialValues, whitelabel));
    }
  }, [whitelabel]);

  useEffect(() => {
    if (initialValues) {
      const newInputFields = [
        <Input name="name" id="editBusinessAndMainContactInformationPopup_name" label="Business name" />,
        <Input name="address1" id="editBusinessAndMainContactInformation_address1" label="Address" />,
        <Input name="city" id="editBusinessAndMainContactInformation_city" label="City" />,
        <SelectDropdown selectOptions={statesArray} name="state" id="editBusinessAndMainContactInformation_state" label="State" />,
        <Input name="postal_code" id="editBusinessAndMainContactInformation_postal_code" maxLength={5} label="Postal code" />,
        <Input name="main_contact_first_name" id="editMainContactInformationPopup_contact_first_name" label="First name" />,
        <Input name="main_contact_last_name" id="editMainContactInformationPopup_contact_last_name" label="Last name" />,
        <Input type="email" name="main_contact_email" id="editMainContactInformationPopup_contact_email" label="Email" />,
        <Input name="main_contact_phone" id="editMainContactInformationPopup_contact_phone" label="Phone" mask="phone" />
      ].filter(field => isNotNullNorUndefined(field));

      if (newInputFields.length % 2 !== 0) {
        setButtonToTheRight(true);
      }
      const finalInputFields = Array.from({ length: Math.ceil(newInputFields.length / 2) }, (_, i) => newInputFields.slice(i * 2, i * 2 + 2));

      let newValidationSchema: any = {
        ...AddressValidation,
        name: BusinessValidation.name,
        main_contact_first_name: NameValidation,
        main_contact_last_name: NameValidation,
        main_contact_email: EmailValidation,
        main_contact_phone: PhoneValidation,
      };

      setInputFields(finalInputFields);
      setValidationSchema(newValidationSchema);
    }
  }, [initialValues]);

  const handleSubmit = async (data: any) => {
    const response = await WhiteLabelHandler.update(whitelabel?.slug, data);
    Analytics.track({ experience: "portal", screen: "settings_account_info", object: "business_info", action: "updated" } as ITracking, { whitelabel_id: response?.id });
    displayMiniFeedback("Information updated");
    dispatch(popUpActions.closePopup());
  }

  return (
    <Popup isInner={true} styleOverride={{ padding: "1.5rem 3.5rem .5rem 3.5rem", gap: "0" }}>
      <>
        {initialValues && inputFields && validationSchema && <>
          <CloseIcon id="editBusinessAndMainContactInformationPopup_closeIcon" color="dark" />
          <Form
            id="editBusinessAndMainContactInformationPopup"
            title="Edit business information"
            submitBtnText="Save"
            onFormSubmit={handleSubmit}
            validationSchema={Schema(validationSchema)}
            values={initialValues}
            buttonPosition={buttonToTheRight ? "side right" : "bottom right"}
            inputFields={inputFields}
            isInsidePopup
            useSmallerTitle
            styleOverride={{ inputError: { top: 50 } }}
          />
        </>}
      </>
    </Popup>
  );
};

export default EditWhitelabelBusinessMainContactPopup;
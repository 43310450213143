import { AxiosError } from "axios";
import { IFeedback } from "common/interfaces";
import { decypherApiErrorResponse, displayFeedback, displayFullscreenLoading } from "utils/helpers";
import Log from "classes/Log";
import LocalStorage from "classes/LocalStorage";
import axios from "axios";

const ErrorHandler = (error: any) => {
  if (error?.code === "ERR_NETWORK") {
    console.debug("An ERR_NETWORK occurred", error);
    return;
  }

  if (error instanceof AxiosError) {
    const status = error.response?.status;
    Log.debug('ERROR HANDLER', error, status);

    if (status === 400) {
      Log.debug('Error Path #1');
      const body = decypherApiErrorResponse(error);
      displayFeedback({ body, title: "Please review the following", type: "GEARS" } as IFeedback);
      return;
    }
    if (status === 403) {
      if (error.response?.data?.detail?.indexOf("You have been locked out") > -1) {
        displayFeedback({ title: "Your account has been locked", body: error.response?.data?.detail, type: "RESTRICTED" } as IFeedback);
        return;
      }
      displayFeedback({ body: error?.response?.data?.detail || "You don't have permission to access this resource.", type: "RESTRICTED" } as IFeedback);
      return;
    }
    if (status === 401 && (error.response?.data?.detail === "Given token not valid for any token type" || error.response?.data?.code === "token_not_valid")) {
      return;
    }
    if (status === 401) {
      if (error.response?.data?.detail?.indexOf("invalid username") > -1) {
        displayFeedback({ title: "Invalid username or password", body: error.response?.data?.detail, type: "RESTRICTED" } as IFeedback);
        return;
      } else if (error.response?.data?.detail) {
        displayFeedback({ body: error.response?.data?.detail, type: "RESTRICTED" } as IFeedback);
        return;
      }
      displayFeedback({
        body: "Sorry, this page is restricted. Please log in.", type: "RESTRICTED", buttons: [
          { id: "login_page_restricted", label: "Log in", action: () => { window.location.href = "/login" } }
        ] as Array<{ id: string, label: string, action: () => void }>
      } as IFeedback);
      return;
    }
    if (status === 404) {
      displayFeedback({ body: "Not found", type: "UFO" } as IFeedback);
      return;
    }
    if (status === 503 || /*error.code === "ERR_NETWORK" ||*/ error.code === "ERR_INTERNET_DISCONNECTED") {
      Log.debug('Error Path #503 or ERR_INTERNET_DISCONNECTED', error);

      axios.get('https://httpbin.org/get')
        .then(() => {
          // internet connection seems to be good
          displayFeedback({
            title: "Oops! Something went wrong!",
            body: "Looks like the server is down - we are on it!\nPlease try again later or contact support.",
            type: "UNPLUGGED"
          } as IFeedback);
        })
        .catch(() => {
          // can't reach public website, internet connection might be broken
          displayFeedback({
            title: "Oops! Something went wrong!",
            body: "Looks like you're offline. Please check your internet connection and try again.",
            type: "BLANK"
          } as IFeedback, true);
        });

      return;
    }
    if (status === 429) {
      Log.debug('Error Path #429');
      Log.debug('429', error);
      displayFeedback({
        title: "Your account has been locked",
        body: "You have been locked out of your account due to too many login attempts. Please try again in 1 hour or reset your password.",
        type: "RESTRICTED"
      } as IFeedback);
      return;
    }
    if (status === 410) {
      displayFeedback({
        title: error.response?.data?.detail,
        body: " ",
        type: "RESTRICTED"
      } as IFeedback);
      return;
    }
  } else {
    console.error("ERROR HANDLER", error);
  }

  // Display generic error
  Log.debug('Error Path #7');
  Log.debug('Unhandled error', error);
  displayFeedback({ body: null, type: "GEARS" } as IFeedback);
}

export default ErrorHandler;

/** 
 * @description Handle deactivated merchant.
 * @param {any} error API error response.
 * */
export const DeactivatedMerchantHandler = (error: any): void => {
  if (error?.response?.status === 403 && error?.response?.data?.detail?.indexOf("this merchant has been deactivated") > -1) {
    displayFullscreenLoading();
    LocalStorage.remove("deactivated-merchant");
    window.location.href = `/deactivated?m=${error?.response?.data?.detail}`;
  } else {
    ErrorHandler(error);
  }
}

/** 
 * @description Handle deactivated partner.
 * @param {any} error API error response.
 * */
export const DeactivatedPartnerHandler = (error: any): void => {
  if (error?.response?.status === 403 && error?.response?.data?.detail?.indexOf("this partner has been deactivated") > -1) {
    displayFullscreenLoading();
    LocalStorage.remove("deactivated-partner");
    window.location.href = `/deactivated?p=${error?.response?.data?.detail}`;
  } else {
    ErrorHandler(error);
  }
}
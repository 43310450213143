import { Aside, DashboardHeader, Grid, InfoContainer, Link, Sidebar } from "common";
import styles from "./Help.module.scss";
import { useAuth } from "auth/useAuth";
import { useAppSelector } from "reducers/Hooks";
import version from "../../version.json";
import Analytics, { ITracking } from "classes/Analytics";
import { IGeneralSupport } from "common/interfaces";
import { EmptySpace } from "common/form";
import _ from "lodash";
import { Notepad2, Call } from "iconsax-react";
import { stripOutNonDigits } from "utils/formatters";
import InnerPopups from "content/popups/InnerPopups";

const Help: React.FC = () => {
  const user = useAuth()?.user;
  const support: IGeneralSupport = useAppSelector(state => state.app.support);

  const phone = user?.user_type === "WHITELABEL" ? support.whitelabel?.phone : support.merchant?.phone;
  const email = user?.user_type === "WHITELABEL" ? support.whitelabel?.email : support.merchant?.email;
  let rowsHelp = [
    {
      label: "Contact name",
      value: user?.user_type === "WHITELABEL" ? support.whitelabel?.name : support.merchant?.name
    },
    {
      label: "Email", value: <Link
        styleOverride={{ color: "var(--primaryTextColor)" }}
        id="support_email"
        href={`mailto:${email}`}
        onClick={() => {
          Analytics.track({ experience: "portal", screen: "help", object: "email_field", action: "clicked" } as ITracking, { support_email: email });
        }}
        linkText={email} />
    }
  ];

  if (phone) {
    rowsHelp.push({
      label: "Phone", value: <Link
        styleOverride={{ color: "var(--primaryTextColor)" }}
        id="support_phone"
        href={`tel:+1${stripOutNonDigits(phone)}`}
        onClick={() => {
          Analytics.track({ experience: "portal", screen: "help", object: "phone_field", action: "clicked" } as ITracking, { support_phone: phone });
        }}
        linkText={phone} />
    });
  }

  const handleResourceLinkClick = (name: string) => {
    Analytics.track({ experience: "portal", screen: "help", object: "additional_resource_link", action: "clicked" } as ITracking, { "resource_name": name });
  }

  let rowsResources = [];
  _.forEach(support?.resources, resource => {
    rowsResources.push({
      label: resource?.name,
      value: resource?.url,
      description: resource?.description,
      onLinkClick: handleResourceLinkClick
    });
  })

  return <main className={styles.applicationsPageContainer}>
    <Sidebar />
    <Aside>
      <DashboardHeader />
      <InnerPopups />
      <div style={{ margin: "0px 3.5rem 3.5rem" }}>
        {support && <Grid horizontal>
          <Grid item>
            <InfoContainer
              id="support_card"
              title={user?.user_type !== "MERCHANT" ? "Support" : "Merchant support"}
              rows={rowsHelp}
              icon={Call}
            />
          </Grid>
          {rowsResources.length > 0 && user.user_type !== "MERCHANT" &&
            <Grid item>
              <InfoContainer
                id="additional_resources_card"
                title={"Additional Resources"}
                rows={rowsResources}
                rowsAsLinks
                icon={Notepad2}
              />
            </Grid>}
          {rowsResources.length === 0 && <Grid item>
            <EmptySpace />
          </Grid>}
        </Grid>}
      </div>
      {(version?.tag_version || version?.commit_version) && <div
        style={{
          position: "absolute",
          bottom: 5,
          right: 10,
          zIndex: 0
        }}>{`Version: ${version?.tag_version || version?.commit_version?.substring(0, 8)}`}
      </div>}
    </Aside>
  </main>;
};
export default Help;

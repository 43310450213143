import React from "react";

interface ISwitch {
  checked: boolean;
  onChange: (event: any, index: number) => void;
  index: number;
  alwaysOn?: boolean;
  backgroundColor?: string;
  id?: string;
}

const Switch: React.FC<ISwitch> = ({ checked, onChange, index, alwaysOn, backgroundColor, id }) => {
  return <>
    <label className="switch">
      <input id={`${id}_switch`} type="checkbox" checked={checked} onChange={(e) => { onChange(e, index) }} />
      <span id={`${id}_toggle`} className={`slider${alwaysOn ? " alwaysOn" : ""}`} style={{ backgroundColor: backgroundColor ? backgroundColor : "" }}></span>
    </label>
  </>;
}

export default Switch;
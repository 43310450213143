import { ENABLE_FINANCING_API_INSTANCE } from "./ActionConstants";
import ErrorHandler from "./ErrorHandler";
import { SUPPORT_INFO } from "utils/constants";
import LocalStorage from "classes/LocalStorage";
import { convertFromNameIdToLabelValue, convertFromObjectToLabelValue } from "utils/helpers";
import store from "reducers/Store";
import appActions from "reducers/AppReducer";
import ApiCache from "classes/ApiCache";
import { IGeneralSupport } from "common/interfaces";
import debounce from 'lodash/debounce';

export default class SystemHandler {
  /**
   * @description Set global headers on axios instances
   * @param {String} accessToken Access token.  
   */
  static setGlobalHeaders = (accessToken: String) => {
    if (accessToken) {
      ENABLE_FINANCING_API_INSTANCE.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    }
  }

  static getLoanPurposeOptions = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get(`loan-applications/options/loan-purpose/?offset=0&limit=1000000`)));
  }

  static getEducationLevelOptions = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get(`loan-applications/options/education-level/?offset=0&limit=1000000`)));
  }

  static getPropertyStatusOptions = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get(`loan-applications/options/property-status/?offset=0&limit=1000000`)));
  }

  static getEmploymentStatusOptions = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get(`loan-applications/options/employment-status/?offset=0&limit=1000000`)));
  }

  static getPayFrequencyOptions = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get(`loan-applications/options/pay-frequency/?offset=0&limit=1000000`)));
  }

  static getCreditRatingOptions = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get(`loan-applications/options/credit-rating/?offset=0&limit=1000000`)));
  }

  static getAnnualSales = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get('customer/annualsales/?offset=0&limit=1000000')));
  }

  static getAverageTicket = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get('customer/averageticket/?offset=0&limit=1000000')));
  }

  static getCustomerStatus = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get('customer/customerstatus/?offset=0&limit=1000000')));
  }

  static getIndustry = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get('customer/industry/?offset=0&limit=1000000')));
  }

  static getIndustrySpecialty = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get('customer/industryspecialty/?offset=0&limit=1000000')));
  }

  static getMonthlyFinanceVolume = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get('customer/monthlyfinancevolume/?offset=0&limit=1000000')));
  }

  static getNumberOfMerchants = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get('customer/merchant-number/?offset=0&limit=1000000')));
  }

  static getPartnerTypes = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get('customer/partner-type/?offset=0&limit=1000000')));
  }

  static getApplicationStatuses = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get('loan-applications/options/application-status/?offset=0&limit=1000000')));
  }

  static getMerchantStatuses = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get('customer/customerstatus/')));
  }

  static getTemplates = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get(`customer/industrytemplate/?test=${(new Date()).getTime()}`)));
  }

  static getColorTheme = async (): Promise<any> => {
    return Promise.resolve(await ApiCache.get('customer/colortheme/?offset=0&limit=1000000'));
  }

  static getRejectOfferOptions = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get(`loan-applications/options/loan-reject-reason/?offset=0&limit=1000000`)));
  }

  static getProgramViewOptions = async (): Promise<any> => {
    return Promise.resolve(convertFromNameIdToLabelValue(await ApiCache.get(`/user/options/program-view/`)));
  }

  /**
   * Asynchronously retrieves the status of applications from the financing API.
   * 
   * @returns {Promise<any>} A promise that resolves with the application status data if successful, or rejects with an error if an error occurs.
   */
  static getUWApplicationStatuses = async (): Promise<any> => {
    try {
      const response = convertFromObjectToLabelValue(await ApiCache.get(`underwriting/application-status/`));
      return Promise.resolve(response);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * Asynchronously retrieves the list of business types from the financing API.
   * 
   * @returns {Promise<any>} A promise that resolves with the business types data if successful, or rejects with an error if an error occurs.
   */
  static getUWBusinessTypes = async (): Promise<any> => {
    try {
      const response = convertFromObjectToLabelValue(await ApiCache.get(`underwriting/business-types/`));
      return Promise.resolve(response);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * Asynchronously retrieves the list of owner titles from the financing API.
   * 
   * @returns {Promise<any>} A promise that resolves with the owner titles data if successful, or rejects with an error if an error occurs.
   */
  static getUWOwnerTitles = async (): Promise<any> => {
    try {
      const response = convertFromObjectToLabelValue(await ApiCache.get(`underwriting/owner-titles/`));
      return Promise.resolve(response);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  private static _fetchSupportInfo = async (): Promise<void> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.get(`support/`);
      let support: IGeneralSupport = {
        merchant: response.data[SUPPORT_INFO.MERCHANT],
        borrower: response.data[SUPPORT_INFO.BORROWER],
        whitelabel: response.data[SUPPORT_INFO.WHITELABEL],
        resources: response.data[SUPPORT_INFO.RESOURCES],
      };
      store.dispatch(appActions.updateSupport(support));
    } catch (error) {
      ErrorHandler(error);
      throw error.response;
    }
  };

  static getSupportInfo = debounce(async () => {
    await SystemHandler._fetchSupportInfo();
  }, 500);
}

window.setTimeout(() => {
  SystemHandler.setGlobalHeaders(LocalStorage.get('access_token') || '');
}, 500);

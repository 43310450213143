import { Input, SelectDropdown, Form } from "common/form";
import { IPersonalInformation, IStepper } from "common/interfaces";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import actions from "reducers/BorrowerReducer";
import { PersonalInfoValidation, Schema, nameMaxChars, AnnualIncomeValidation } from "utils/validation/additionalValidation";
import BorrowerHandler from "actions/BorrowerHandler";
import { useEffect, useState } from "react";
import LocalStorage from "classes/LocalStorage";
import { convertDateFormatSlashToDash, stripOutNonDigits } from "utils/formatters";
import useGetLoanApplication from "hooks/useGetLoanApplication";
import _ from "lodash";
import { askForConfirmation } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";

const DTMPersonalInformation: React.FC<IStepper> = ({ onNext, onBack }) => {
  const dispatch = useAppDispatch();

  useGetLoanApplication();

  const vuid = LocalStorage.get<string>("vuid");
  const applicationId = LocalStorage.get<number>("application_id");
  const applicationLocked = LocalStorage.get<boolean>("application_locked");
  const personalInformation = useAppSelector(state => state.borrower.personalInformation);
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    annual_income: 0,
  });

  useEffect(() => {
    if (personalInformation) {
      setInitialValues(personalInformation);
    }
  }, [personalInformation]);

  const handleSubmit = async (data: IPersonalInformation) => {
    let payload = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      date_of_birth: convertDateFormatSlashToDash(data?.date_of_birth),
      annual_income: parseInt(stripOutNonDigits((data.annual_income || 0).toString())),
    };

    if (payload.annual_income < 15000) {
      askForConfirmation(`Are you sure that you have entered the annual (yearly) amount and not the monthly amount?`,
        { text: 'Yes, I am sure', action: () => { dispatch(popUpActions.closePopup()); goToNextScreen(data, payload) } },
        { text: 'No, take me back', action: () => { dispatch(popUpActions.closePopup()) } }, true, null,
        `Looks like the annual amount is under $15,000`,
        "/assets/images/annual_amount.svg"
      );
    } else if (payload.annual_income > 500000) {
      askForConfirmation(`Are you sure that you have entered the annual (yearly) amount?`,
        { text: 'Yes, I am sure', action: () => { dispatch(popUpActions.closePopup()); goToNextScreen(data, payload) } },
        { text: 'No, take me back', action: () => { dispatch(popUpActions.closePopup()) } }, true, null,
        `Looks like the annual amount is over $500,000`,
        "/assets/images/annual_amount.svg"
      );
    } else {
      goToNextScreen(data, payload);
    }
  }

  const goToNextScreen = async (data: IPersonalInformation, payload: any) => {
    if (applicationId && vuid) {
      const response = await BorrowerHandler.savePersonalInformation(payload, applicationId, vuid);
      if (response) {
        if (response?.date_of_birth) {
          LocalStorage.save("dob", response?.date_of_birth);
        }
        dispatch(actions.setPersonalInformation(data));
        window.parent.postMessage({ applicationId, vuid }, '*');
        onNext();
      }
    }
  }

  return (
    <Form
      id="borrowerExperience_personalInformation"
      title="Personal information"
      submitBtnText="Next"
      onFormSubmit={handleSubmit}
      validationSchema={Schema({
        ...PersonalInfoValidation,
        annual_income: AnnualIncomeValidation,
      })}
      values={initialValues}
      overrideValues={personalInformation}
      buttonPosition="bottom right"
      inputFields={[
        [
          <Input type="text" name="first_name" id="borrowerExperience_first_name" label="First name" maxLength={nameMaxChars} />,
          <Input type="text" name="last_name" id="borrowerExperience_last_name" label="Last name" maxLength={nameMaxChars} />,
        ],
        [
          <Input type="text" name="date_of_birth" id="borrowerExperience_date_of_birth" label="Date of birth" mask="date" />,
          <Input type="text" name="annual_income" id="borrowerExperience_annual_income" label="Annual income*" mask="amount" inputPrefix="$" />,
        ]
      ]}
      onBackClick={onBack}
      disabledInputFields={applicationLocked}
    />
  );
};

export default DTMPersonalInformation;

import { AppIcon, Button, Padding } from "common";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "reducers/Hooks";
import { IBusinessInfo, IContact, IFeedback, IPartnerBusinessInfo, IPopup } from "common/interfaces";
import {
  decypherApiErrorResponse, displayFeedback, displayFullscreenLoading,
  displayMiniFeedback, getAPIErrorForTracking, hideFullscreenLoading
} from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { useNavigate } from "react-router-dom";
import BusinessInformationForm from "./addNewPartner/BusinessInformationForm";
import MainContactInformationForm from "./addNewPartner/MainContactInformationForm";
import { Add, ArrowLeft } from "iconsax-react";
import Log from "classes/Log";
import NewPopup from "./NewPopup";
import styles from "./AddEntityPopup.module.scss";
import Analytics, { ITracking } from "classes/Analytics";
import { PartnerHandler } from "actions/PartnerHandler";

const partnerInitialState = {
  businessInformation: { external_id: "", name: "", address1: "", city: "", postal_code: "", state: "", website: "", merchant_number: "", type: "" },
  mainContact: { main_contact_first_name: "", main_contact_last_name: "", main_contact_email: "", main_contact_phone: "" },
};

const AddPartnerPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [partner, setPartner] = useState<any>(partnerInitialState);
  const [partnerSlug, setPartnerSlug] = useState<any>(null);
  const [originalPartnerSlug, setOriginalPartnerSlug] = useState<string>(null);
  const [displayConfirmation, setDisplayConfirmation] = useState<boolean>(false);

  const trackingScreens = [
    "add_partner_business_info",
    "add_partner_main_contact"
  ];

  useEffect(() => {
    setPartnerSlug(null);
    setOriginalPartnerSlug(null);
    setPartner(partnerInitialState);
    setStep(0);
  }, []);

  const finishFlow = (slug: string) => {
    PartnerHandler().finalize(slug)
      .then(() => {
        dispatch(popUpActions.closePopup());
        hideFullscreenLoading();
        displayFeedback({
          title: `You have successfully added ${partner?.businessInformation?.name}!`,
          body: `Their account will now become active and their main contact will get an email to create a user account.`,
          type: "PARTNER",
          buttons: [
            {
              id: "view_partner_btn",
              label: "View partner",
              action: () => {
                dispatch(popUpActions.closePopup());
                navigate(`/viewPartner/${slug}/accountInformation`);
              }
            }
          ]
        } as IFeedback);
      });
  }

  const saveData = (data: any, type: string) => {
    setPartner({
      ...partner,
      [type]: data
    });
  }

  const handleBackButton = () => {
    if (step === 0) {
      dispatch(popUpActions.closePopup());
    } else {
      setStep(step - 1);
    }
  }

  const patch = async (data: any, nextStep: number) => {
    return new Promise((resolve, reject) => {
    PartnerHandler(displayErrors).patch(partnerSlug, data)
      .then((response: any) => {
        Analytics.track({ experience: "portal", screen: trackingScreens[step], object: "form_submission", action: "successful" } as ITracking);
        setStep(nextStep);
        resolve(response);
      })
      .catch(error => {
        Analytics.track({ experience: "portal", screen: trackingScreens[step], object: "form_submission", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error) });
        reject(error);
      });
    });
  }

  const displayErrors = (error: any): void => {
    Log.error(error);
    const body = decypherApiErrorResponse(error);
    displayMiniFeedback(body, true);
  }

  const renderStep = () => {
    const components = [
      <BusinessInformationForm initialValues={partner?.businessInformation}
        onNext={(data: IPartnerBusinessInfo) => {
          saveData(data, 'businessInformation');
          if (partnerSlug) {
            patch(data, 1);
          } else {
            PartnerHandler(displayErrors).create(data)
              .then(response => {
                setPartnerSlug(response.slug);
                setOriginalPartnerSlug(response.slug);
                setStep(1);
                Analytics.track({ experience: "portal", screen: "add_partner_business_info", object: "form_submission", action: "successful" } as ITracking);
              });
          }
        }} />,
        <Padding top="1.5rem" bottom="0" left="0" right="0">
          <MainContactInformationForm initialValues={partner?.mainContact}
            onNext={(data: IContact) => {
              saveData(data, 'mainContact');
              patch(data, 2)
              .then((response: any) => {
                finishFlow(response?.slug);
              })
              .catch((error: any) => {
                console.log(error);
              });
            }} />
        </Padding>
    ];

    return components[step] || null;
  }

  const closeForm = () => {
    setPartnerSlug(null);
    setPartner(partnerInitialState);
    setStep(0);
    setDisplayConfirmation(false);
    dispatch(popUpActions.closePopup());
    hideFullscreenLoading();
  }

  const handleClose = () => {
    displayFullscreenLoading();
    if (partnerSlug) {
      PartnerHandler(displayErrors).deleteIncomplete(originalPartnerSlug)
        .then(() => {
          Analytics.track({ experience: "portal", screen: "add_partner_abandon_form", object: "delete_partner_info", action: "successful" } as ITracking, { screen_abandoned: trackingScreens[step] });
          closeForm();
        })
        .catch(error => {
          Log.error(error);
          hideFullscreenLoading();
          displayMiniFeedback("Something went wrong. Please try again.", true);
        });
    } else {
      closeForm();
    }
  }

  return <NewPopup isInner style={{ maxWidth: "765px", padding: "1.5rem 3.5rem .5rem 3.5rem" }}>
    <>
      {displayConfirmation && <div style={{ margin: "3.5rem 1.5rem 4.5rem 1.5rem" }}>
        <p className={styles.confirmation}>
          Closing the form without finalizing the new partner<br />will result in losing your progress
        </p>
        <div className={styles.buttons}>
          <Button id="addPartner_confirmLoss" label="Confirm loss" variant="secondary" onClick={handleClose} />
          <Button id="addPartner_stayForm" label="Stay on form" onClick={() => { 
            setDisplayConfirmation(false);
            Analytics.track({ experience: "portal", screen: "add_partner_abandon_form", object: "stay_on_form", action: "successful" } as ITracking, { screen_abandoned: trackingScreens[step] });
          }} />
        </div>
      </div>}
      <div style={{ display: displayConfirmation ? "none" : "block" }}>
        {step > 0 && <AppIcon
          size={26}
          color="var(--darkTextColor)"
          clickTrigger={{ id: "addPartner_back", onClick: handleBackButton }}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: 15,
            left: 20,
            width: 40,
            height: 26,
            zIndex: 1,
          }}
          icon={ArrowLeft}
        />}
        <AppIcon
          clickTrigger={{
            id: "addPartnerPopup", onClick: () => {
              Analytics.track({ experience: "portal", screen: trackingScreens[step], object: "close_modal", action: "clicked" } as ITracking);
              setDisplayConfirmation(true);
            }
          }}
          color="var(--darkTextColor)"
          size={35}
          className={styles.crossIcon}
          icon={Add}
        />
        {renderStep()}
      </div>
    </>
  </NewPopup>;
};

export default AddPartnerPopup;
import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { displayFeedback } from "utils/helpers";
import { IFeedback } from "./interfaces";
import queryString from "query-string";
import LocalStorage from "classes/LocalStorage";
import { useLoadingBar } from "hooks/useLoadingBar";
import { debounce } from "lodash";

interface IURLHandler {
  children: any;
  requireVisitorPass?: boolean;
  requireParams?: Array<string>;
  redirectUrl: string;
  errorMessage?: string;
}

const URLHandler: React.FC<IURLHandler> = ({ children, redirectUrl, errorMessage = "", requireParams = [], requireVisitorPass = false }): ReactElement => {
  const params = useParams();
  const navigate = useNavigate();
  const urlParams = queryString.parse(window.location.search);
  const [done, setDone] = useState(false);
  useLoadingBar(done);

  useEffect(() => {
    let requiredInfoProvided = true;
    if (requireParams.length > 0) {
      requireParams.forEach(param => {
        let element = params[param] || urlParams[param] || null;
        if (element) {
          LocalStorage.save(param, element);
        } else {
          requiredInfoProvided = false;
        }
      });
    }

    if (requireVisitorPass) {
      if (urlParams["vuid"]) {
        LocalStorage.save("vuid", urlParams["vuid"]);
      } else {
        requiredInfoProvided = false;
      }
    }

    if (!requiredInfoProvided) {
      if (errorMessage) {
        displayFeedback({ body: errorMessage, type: "GEARS", buttons: [{ label: "Close", id: "feedback_close_btn", action: () => navigate(redirectUrl) }] } as IFeedback);
      } else {
        navigate(redirectUrl);
      }
    }

    const debouncedSetDone = debounce(() => {
      setDone(true);
    }, 500);
    
    debouncedSetDone();
    
  }, [urlParams]);

  return <>
    {done && <>
      {children}
    </>}
  </>;
}
export default URLHandler;

// used only for partner settings
import styles from "./Settings.module.scss";
import React, { useEffect, useState } from "react";
import { Grid, InfoContainer, Switch } from "common";
import { EmptySpace } from "common/form";
import { useAuth } from "auth/useAuth";
import UserHandler from "actions/UserHandler";
import { SmsNotification } from "iconsax-react";
import Observer, { EVENTS } from "classes/Observer";

const Notifications: React.FC = () => {
  const [notifications, setNotifications] = useState([]);
  const user = useAuth()?.user;
  const observerNotificationUpdated = Observer.useObserver(EVENTS.NOTIFICATIONS_UPDATED);

  useEffect(() => {
    UserHandler.getNotificationSettings(user.id)
      .then(response => {
        setNotifications(response);
      });
  }, [user, observerNotificationUpdated]);

  const handleChange = (e: any, i: number) => {
    if (notifications && notifications[i]) {
      const action = e.target.checked ? UserHandler.subscribeToNotification : UserHandler.unsubscribeFromNotification;
      action(notifications[i].id, notifications[i].notification?.name);
    }
  };

  return <div className={styles.contentContainer}>
    <h2 className={styles.pageTitle}>Your notifications</h2>
    <Grid horizontal>
      <Grid item>
        <InfoContainer
          id="notifications_card"
          title="Email notifications"
          icon={SmsNotification}
        >
          <Grid>
            {notifications.map((notification: any, i: any) =>
              <Grid horizontal key={`email_settings_menu_${i}`}>
                <Grid item><span className="menu">{notification.notification.name}</span></Grid>
                <Grid item alignRight>
                  <Switch checked={notification.setting} onChange={handleChange} index={i} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </InfoContainer>
      </Grid>
      <Grid item>
        <EmptySpace />
      </Grid>
    </Grid>
  </div>;
};

export default Notifications;

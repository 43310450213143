import styles from "./Settings.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { ApplicationMenu, Aside, DashboardHeader, Sidebar } from "common";
import {
  Experience, LandingPagePortal, Locations,
  Notifications, Users, MerchantHistory, MerchantAccountInformation, MerchantAccountInformation2
} from ".";
import { merchantDetailsMenu } from "utils/constants";
import { useAuth } from "auth/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { getStatus } from "utils/helpers";
import { MerchantHandler } from "actions/MerchantHandler";
import Observer, { EVENTS } from "classes/Observer";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import appActions from "reducers/AppReducer";
import { useFlag } from "hooks/useFlag";
import InnerPopups from "content/popups/InnerPopups";
import useMerchant from "hooks/useMerchant";
import { MerchantTabType } from "common/types";

interface IViewMerchant {
  view: string;
}

const ViewMerchant: React.FC<IViewMerchant> = ({ view }) => {
  const dispatch = useAppDispatch();
  const { merchantSlug } = useParams();
  const merchant = useMerchant();
  const merchantUpdated = Observer.useObserver(EVENTS.MERCHANT_UPDATED);
  const navigate = useNavigate();
  const user = useAuth()?.user;

  const pendingStatus = useRef<number>(null);
  const deactivatedStatus = useRef<number>(null);
  const declinedStatus = useRef<number>(null);

  const [applications, setApplications] = useState(null);
  const [ready, setReady] = useState(false);

  if (user?.user_type === "MERCHANT") {
    navigate('/settings/accountInformation');
    return;
  }

  useEffect(() => {
    Promise.all([
      getStatus("Pending"),
      getStatus("Deactivated"),
      getStatus("Declined")
    ]).then(responses => {
      pendingStatus.current = responses[0];
      deactivatedStatus.current = responses[1];
      declinedStatus.current = responses[2];
      setReady(true);
    });
  }, []);

  useEffect(() => {
    return () => {
      dispatch(appActions.clearBreadcrumbs());
    }
  }, []);

  useEffect(() => {
    if (user?.user_type === "MERCHANT") {
      navigate('/settings/accountInformation');
    }
  }, [user]);

  useEffect(() => {
    if (ready) {
      if (window.location.href.includes("bc=1")) {
        dispatch(appActions.setBreadcrumbs([
          { title: `${merchant?.partner?.name}`, url: `/viewPartner/${merchant?.partner?.slug}/merchants` }
        ]));
      }
    }
  }, [merchant, applications, ready]);

  useEffect(() => {
    if (merchantSlug || !merchant?.id) {
      LoanApplicationHandler.getMany(null, { merchant: merchantSlug })
        .then(applicationsList => {
          setApplications(applicationsList.count)
        });
    }
  }, [merchantSlug]);

  useEffect(() => {
    MerchantHandler().get(merchantSlug || merchant?.slug);
  }, [merchantUpdated]);

  return <main className={styles.settingsPageContainer}>
    <Sidebar />
    <Aside>
      <DashboardHeader />
      <InnerPopups />
      {<MerchantAccountInformation2 view={view as MerchantTabType} />}

    </Aside >
  </main >;
};

export default ViewMerchant;

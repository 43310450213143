import { AppIcon, Button, Padding } from "common";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IBusinessInfo, IContact, IFeedback, IPopup } from "common/interfaces";
import {
  decypherApiErrorResponse, displayFeedback, displayFullscreenLoading,
  displayMiniFeedback, getAPIErrorForTracking, hideFullscreenLoading
} from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { useNavigate } from "react-router-dom";
import BusinessInformationForm from "./addNewMerchant/BusinessInformationForm";
import BusinessMainAddressForm from "./addNewMerchant/BusinessMainAddressForm";
import AdditionalLocationsForm from "./addNewMerchant/AdditionalLocationsForm";
import MainContactInformationForm from "./addNewMerchant/MainContactInformationForm";
import LandingPageSettingsForm from "./addNewMerchant/LandingPageSettingsForm";
import { Add, ArrowLeft } from "iconsax-react";
import Log from "classes/Log";
import NewPopup from "./NewPopup";
import { MerchantHandler } from "actions/MerchantHandler";
import styles from "./AddEntityPopup.module.scss";
import LenderGroupSelectionForm from "./addNewMerchant/LenderGroupSelectionForm";
import Analytics, { ITracking } from "classes/Analytics";
import { useAuth } from "auth/useAuth";

const merchantInitialState = {
  businessInformation: { external_id: "", name: "", website: "", annual_sales: "", monthly_financing_volume: "", average_ticket: "", industry: "", industry_specialty: "" },
  mainAddress: { address1: "", city: "", postal_code: "", state: "", manageMultipleLocations: "" },
  landingPage: { slug: "", color_theme: null, industry_template: null },
  additionalLocations: { name: "", address1: "", city: "", postal_code: "", state: "" },
  mainContact: { main_contact_first_name: "", main_contact_last_name: "", main_contact_email: "", main_contact_phone: "" },
  lenderGroup: { lender_group: null, max_loan_amount: null }
};

const AddMerchantPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAuth()?.user;
  const whitelabel = useAppSelector(state => state.whitelabel);
  const [step, setStep] = useState(0);
  const [merchant, setMerchant] = useState<any>(merchantInitialState);
  const [merchantSlug, setMerchantSlug] = useState<any>(null);
  const [originalMerchantSlug, setOriginalMerchantSlug] = useState<string>(null);
  const [displayConfirmation, setDisplayConfirmation] = useState<boolean>(false);

  const trackingScreens = [
    "add_merchant_business_info",
    "add_merchant_main_address",
    "add_merchant_add_locations",
    "add_merchant_main_contact",
    "add_merchant_program_settings",
    "add_merchant_landing_page"
  ];

  useEffect(() => {
    setMerchantSlug(null);
    setOriginalMerchantSlug(null);
    setMerchant(merchantInitialState);
    setStep(0);
  }, []);

  useEffect(() => {
    setMerchant({
      ...merchant,
      landingPage: {
        ...merchant?.landingPage,
        slug: merchantSlug
      }
    });
  }, [merchantSlug]);

  const finishFlow = (slug: string) => {
    dispatch(popUpActions.closePopup());
    hideFullscreenLoading();
    displayFeedback({
      title: user?.user_type === "PARTNER" ? `New merchant request sent for ${merchant?.businessInformation?.name}` : `You have successfully added a new merchant: ${merchant?.businessInformation?.name}!`,
      body: user?.user_type === "PARTNER" ? `A request to add the merchant ${merchant?.businessInformation?.name} has been sent to ${whitelabel?.name} for approval. Once approved, the merchant's account will become active, and their main contact will receive an email to create a user account. You will be notified when the merchant is activated.` : `Their account will now become active and their main contact will get an email to create a user account.`,
      type: "MERCHANT",
      buttons: [
        {
          id: "view_merchant_btn",
          label: "View merchant",
          action: () => {
            dispatch(popUpActions.closePopup());
            navigate(`/viewMerchant/${slug}/accountInformation`);
          }
        }
      ]
    } as IFeedback);
  }

  const saveData = (data: any, type: string) => {
    setMerchant({
      ...merchant,
      [type]: data
    });
  }

  const handleBackButton = () => {
    if (step === 0) {
      dispatch(popUpActions.closePopup());
    } else {
      if (merchant?.mainAddress.manageMultipleLocations === "no" && step === 3) {
        setStep(1);
      } else if (user?.user_type === "PARTNER" && step === 5) {
        setStep(3);
      } else {
        setStep(step - 1);
      }
    }
  }

  const patch = (data: any, nextStep: number) => {
    MerchantHandler(displayErrors).patch(merchantSlug, data)
      .then(() => {
        Analytics.track({ experience: "portal", screen: trackingScreens[step], object: "form_submission", action: "successful" } as ITracking);
        setStep(nextStep);
      })
      .catch(error => {
        Analytics.track({ experience: "portal", screen: trackingScreens[step], object: "form_submission", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error) });
      });
  }

  const displayErrors = (error: any): void => {
    Log.error(error);
    const body = decypherApiErrorResponse(error);
    displayMiniFeedback(body, true);
  }

  const renderStep = () => {
    const components = [
      <BusinessInformationForm initialValues={merchant?.businessInformation}
        onNext={(data: IBusinessInfo) => {
          saveData(data, 'businessInformation');
          if (merchantSlug) {
            patch(data, 1);
          } else {
            MerchantHandler(displayErrors).create(data)
              .then(response => {
                setMerchantSlug(response.slug);
                setOriginalMerchantSlug(response.slug);
                setStep(1);
                Analytics.track({ experience: "portal", screen: "add_merchant_business_info", object: "form_submission", action: "successful" } as ITracking);
              });
          }
        }} />,
      <Padding top="1.5rem" bottom="0" left="0" right="0">
        <BusinessMainAddressForm initialValues={merchant?.mainAddress}
          onNext={(data: any) => {
            saveData(data, 'mainAddress');
            patch(data, data.manageMultipleLocations === "yes" ? 2 : 3);
            if (data.manageMultipleLocations === "no") {
              // bypass the multiple locations screen
              Analytics.track({ experience: "portal", screen: "add_merchant_add_locations", object: "form_submission", action: "bypassed" } as ITracking);
            }
          }} />
      </Padding>,
      <Padding top="1.5rem" bottom="0" left="0" right="0">
        <AdditionalLocationsForm initialValues={merchant?.additionalLocations}
          slug={merchantSlug}
          mainAddress={merchant?.mainAddress}
          onNext={(data) => {
            saveData(data, 'additionalLocations');
            setStep(3);
          }} />
      </Padding>,
      <Padding top="1.5rem" bottom="0" left="0" right="0">
        <MainContactInformationForm initialValues={merchant?.mainContact}
          onNext={(data: IContact) => {
            saveData(data, 'mainContact');
            patch(data, user?.user_type === "PARTNER" ? 5 : 4);
          }} />
      </Padding>,
      <Padding top="1.5rem" bottom="0" left="0" right="0">
        <LenderGroupSelectionForm initialValues={merchant?.lenderGroup}
          onNext={(data: any) => {
            saveData(data, 'lenderGroup');
            patch(data, 5);
          }} />
      </Padding>,
      <Padding top="1.5rem" bottom="0" left="0" right="0">
        <LandingPageSettingsForm initialValues={merchant?.landingPage}
          onNext={(data) => {
            setMerchantSlug(data.formValues.slug);
            saveData({ slug: data.formValues.slug }, 'landingPage');
            MerchantHandler(displayErrors).updateTemplate(data.file, originalMerchantSlug, data.formValues.slug,
              data.formValues.color_theme, data.formValues.industry_template, null, null, user?.user_type === "WHITELABEL",
              user?.user_type === "PARTNER")
              .then(response => {
                setOriginalMerchantSlug(response?.slug);
                setMerchantSlug(response?.slug);
                finishFlow(response?.slug);
              });
          }}
          businessName={merchant?.businessInformation?.name}
        />
      </Padding>
    ];

    return components[step] || null;
  }

  const closeForm = () => {
    setMerchantSlug(null);
    setMerchant(merchantInitialState);
    setStep(0);
    setDisplayConfirmation(false);
    dispatch(popUpActions.closePopup());
    hideFullscreenLoading();
  }

  const handleClose = () => {
    displayFullscreenLoading();
    if (merchantSlug) {
      MerchantHandler(displayErrors).deleteIncompleteMerchant(originalMerchantSlug)
        .then(() => {
          Analytics.track({ experience: "portal", screen: "add_merchant_abandon_form", object: "delete_merchant_info", action: "successful" } as ITracking, { screen_abandoned: trackingScreens[step] });
          closeForm();
        })
        .catch(error => {
          Log.error(error);
          hideFullscreenLoading();
          displayMiniFeedback("Something went wrong. Please try again.", true);
        });
    } else {
      closeForm();
    }
  }

  return <NewPopup isInner style={{ maxWidth: "765px", padding: "1.5rem 3.5rem .5rem 3.5rem" }}>
    <>
      {displayConfirmation && <div style={{ margin: "3.5rem 1.5rem 4.5rem 1.5rem" }}>
        <p className={styles.confirmation}>
          Closing the form without finalizing the new merchant<br />will result in losing your progress
        </p>
        <div className={styles.buttons}>
          <Button id="addMerchant_confirmLoss" label="Confirm loss" variant="secondary" onClick={handleClose} />
          <Button id="addMerchant_stayForm" label="Stay on form" onClick={() => {
            setDisplayConfirmation(false);
            Analytics.track({ experience: "portal", screen: "add_merchant_abandon_form", object: "stay_on_form", action: "successful" } as ITracking, { screen_abandoned: trackingScreens[step] });
          }} />
        </div>
      </div>}
      <div style={{ display: displayConfirmation ? "none" : "block" }}>
        {step > 0 && <AppIcon
          size={26}
          color="var(--darkTextColor)"
          clickTrigger={{ id: "addMerchant_back", onClick: handleBackButton }}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: 15,
            left: 20,
            width: 40,
            height: 26,
            zIndex: 1,
          }}
          icon={ArrowLeft}
        />}
        <AppIcon
          clickTrigger={{
            id: "addMerchantPopup", onClick: () => {
              Analytics.track({ experience: "portal", screen: trackingScreens[step], object: "close_modal", action: "clicked" } as ITracking);
              setDisplayConfirmation(true);
            }
          }}
          color="var(--darkTextColor)"
          size={35}
          className={styles.crossIcon}
          icon={Add}
        />
        {renderStep()}
      </div>
    </>
  </NewPopup>;
};

export default AddMerchantPopup;
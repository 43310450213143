import { CloseIcon } from "common";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { getRequestRefundAmountValidation, Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";
import { Form, Input, TextArea } from "common/form";
import { formatAmount, stripOutNonDigits } from "utils/formatters";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import IRefund from "common/interfaces/table/IRefund";
import popUpActions from "reducers/PopUpReducer";

const RequestRefundPopup: React.FC<IPopup> = () => {
  const message = useAppSelector(state => state.popUp.messagePassing);
  const dispatch = useAppDispatch();

  const handleSubmit = (data: any) => {
    const refund: IRefund = {
      amount: Number(stripOutNonDigits(data?.amount)),
      reason: "OTHER",// for MVP only
      reason_note: data?.reason,
    }
    LoanApplicationHandler.requestRefund(message?.id, refund);
    dispatch(popUpActions.closePopup());
  }

  return (
    <Popup isInner={true} maxWidth={700}>
      <>
        <CloseIcon id="requestRefundPopup" color="dark" />
        <Form
          id="requestRefund"
          buttonPosition="bottom right"
          onFormSubmit={handleSubmit}
          title={`Request a refund for $${formatAmount(message?.loan_amount)} loan`}
          submitBtnText="Next"
          validationSchema={Schema({
            amount: getRequestRefundAmountValidation(Number(message?.loan_amount)),
            reason: SimpleRequireValidation,
          })}
          values={{
            amount: 0,
            reason: "",
          }}
          styleOverride={{ formHelp: { marginTop: "20px", maxWidth: "60%" }, inputError: { top: 80 } }}
          inputFields={[
            [
              <Input type="text" name="amount" id="requestRefund_amount" label="Amount" mask="amount" inputPrefix="$" />,
            ],
            [
              <TextArea id="requestRefund_reason" label="Reason" name="reason" />,
            ]
          ]}
          isInsidePopup
        />
      </>
    </Popup >
  );
};

export default RequestRefundPopup;

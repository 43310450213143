import { useEffect, useState } from "react";
import moment from "moment";

export const useCooldown = (timeSec: number) => {
  const [countdown, setCountdown] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [lastSent, setLastSent] = useState(null);

  useEffect(() => {
    if (endTime) {
      const updateCountdown = () => {
        const currentTime = moment();
        const diff = moment.duration(endTime.diff(currentTime));

        if (diff.asSeconds() <= 0) {
          clearInterval(intervalId as NodeJS.Timeout);
          setCountdown(null);
          setEndTime(null);
          return;
        }

        const minutes = diff.minutes();
        const seconds = diff.seconds();
        setCountdown(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
      };

      updateCountdown(); // Ensure it updates immediately
      const id = setInterval(updateCountdown, 1000);
      setIntervalId(id);

      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }
  }, [endTime]);

  /**
 * Sets cooldown either from now or from a given timestamp
 * @param timestamp - optional timestamp to start cooldown from
 */
  const setCooldown = (timestamp?: string | null) => {
    const baseTime = timestamp ? moment(timestamp) : moment();
    setLastSent(baseTime.format("MM/DD/YY h:mm a z"));
    setEndTime(baseTime.add(timeSec, "seconds"));
  };

  /** Checks if the cooldown has expired */
  const canResend = () => !endTime || moment().isAfter(endTime);

  return { countdown, lastSent, setCooldown, canResend };
};
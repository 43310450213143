import { Form, Input } from "common/form";
import { IAddEntity } from "common/interfaces";
import React from "react";
import { ContactValidation, Schema } from "utils/validation/additionalValidation";

const MainContactInformationForm: React.FC<IAddEntity> = ({ onNext, initialValues }) => {

  return <Form
    id="addPartnerPopup_mainContactInformation"
    buttonPosition="bottom right"
    onFormSubmit={(data: any) => { onNext(data); }}
    title="Add partner • Main contact information"
    submitBtnText="Finish"
    validationSchema={Schema({
      main_contact_first_name: ContactValidation.main_contact_first_name,
      main_contact_last_name: ContactValidation.main_contact_last_name,
      main_contact_email: ContactValidation.main_contact_email,
      main_contact_phone: ContactValidation.main_contact_phone
    })}
    values={initialValues}
    inputFields={[
      [
        <Input type="text" name="main_contact_first_name" id="addPartnerPopup_contact_first_name" label="First name*" />,
        <Input type="text" name="main_contact_last_name" id="addPartnerPopup_contact_last_name" label="Last name*" />,
      ],
      [
        <Input type="email" name="main_contact_email" id="addPartnerPopup_contact_email" label="Email*" tooltip="This email address will receive an invitation to create a username and password once your account is approved." />,
        <Input type="text" name="main_contact_phone" id="addPartnerPopup_contact_phone" label="Phone number*" mask="phone" />
      ]
    ]}
    isInsidePopup
    useSmallerTitle
  />;
}

export default MainContactInformationForm;
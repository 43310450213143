import { CloseIcon, Scrollable } from "common";
import { Input, Form, SelectDropdown } from "common/form";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { EmailValidation, PersonalInfoValidation, Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";
import { displayMiniFeedback, objectsAreEqual, scrollTop } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { useAuth } from "auth/useAuth";
import UserHandler from "actions/UserHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { useLoadingBar } from "hooks/useLoadingBar";
import SystemHandler from "actions/SystemHandler";
import { useIsDtmEnabled } from "hooks/useIsDtmEnabled";
import { PROGRAM_VIEWS } from "utils/constants";
import LocalStorage from "classes/LocalStorage";
import Observer, { EVENTS } from "classes/Observer";

const EditUserInformationPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const user = useAuth()?.user;
  const setUser = useAuth()?.setUser;
  const [initialValues, setInitialValues] = useState<any>(null);
  useLoadingBar(initialValues);
  const [programViewOptions, setProgramViewOptions] = useState([]);
  const { isDtmEnabled } = useIsDtmEnabled();

  useEffect(() => {
    SystemHandler.getProgramViewOptions()
      .then(response => {
        setProgramViewOptions(response);
      });
    scrollTop();
  }, [])

  useEffect(() => {
    if (user && isDtmEnabled !== null) {
      setInitialValues({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        ...(isDtmEnabled ? { program_view: user.program_view } : {}),
      });
    }
  }, [user, isDtmEnabled]);

  const handleClose = () => {
    dispatch(popUpActions.closePopup());
  }

  const handleSubmit = async (data: any) => {
    if (!objectsAreEqual(data, initialValues)) {
      const newProfile = await UserHandler.updateProfile(data);
      setUser(newProfile);
      if (initialValues?.email?.toLowerCase() !== data?.email?.toLowerCase()) {
        Analytics.track({ experience: "portal", screen: "settings_account_info", object: "user_email", action: "updated" } as ITracking, { changes: { old: { email: initialValues?.email }, new: { email: data?.email } } });
      }
      if (initialValues?.last_name?.toLowerCase() !== data?.last_name?.toLowerCase() ||
        initialValues?.first_name?.toLowerCase() !== data?.first_name?.toLowerCase()) {
        Analytics.track({ experience: "portal", screen: "settings_account_info", object: "user_info", action: "updated" } as ITracking, { changes: { old: { first_name: initialValues?.first_name, last_name: initialValues?.last_name }, new: { first_name: data?.first_name, last_name: data?.last_name }, } });
      }

      // set new business model based on that
      if (newProfile?.program_view === PROGRAM_VIEWS.HIDE_DTC) {
        LocalStorage.save("business_model", "DTM");
        Observer.trigger(EVENTS.BUSINESS_MODEL_UPDATED);
      } else if (newProfile?.program_view === PROGRAM_VIEWS.HIDE_DTM) {
        LocalStorage.save("business_model", "DTC");
        Observer.trigger(EVENTS.BUSINESS_MODEL_UPDATED);
      }

      Observer.trigger(EVENTS.PROGRAM_VIEW_UPDATED);

      displayMiniFeedback('Your account was updated');
    } else {
      displayMiniFeedback('Nothing was changed in your account');
    }
    handleClose();
  }

  return (
    <Popup isInner={true} styleOverride={{ padding: "1.5rem 3.5rem .5rem 3.5rem", gap: "0" }}>
      <>
        {initialValues && <>
          <CloseIcon id="editUserInformationPopup_closeIcon" color="dark" />
          <Form
            id="editUserInformationPopup"
            title="Edit your user information"
            submitBtnText="Save"
            onFormSubmit={handleSubmit}
            validationSchema={Schema({
              first_name: PersonalInfoValidation.first_name,
              last_name: PersonalInfoValidation.last_name,
              email: EmailValidation,
              ...(isDtmEnabled && { program_view: SimpleRequireValidation }),
            })}
            values={initialValues}
            buttonPosition={isDtmEnabled ? "bottom right" : "side right"}
            inputFields={[
              [
                <Input name="first_name" id="editUserInformationPopup_first_name" label="First name" />,
                <Input name="last_name" id="editUserInformationPopup_last_name" label="Last name" />,
              ],
              [
                <Input type="email" name="email" id="editUserInformationPopup_email" label="Email" />,
                isDtmEnabled && <SelectDropdown id="businessModel_programView" label="Program view" name="program_view" placeholder="Select" selectOptions={programViewOptions} />
              ].filter(Boolean)// removes false, null or undefined entries
            ]}
            isInsidePopup
            useSmallerTitle
          />
        </>}
      </>
    </Popup>
  );
};

export default EditUserInformationPopup;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IFeedback } from "common/interfaces";
import { POPUPS } from "utils/constants";
import Log from "classes/Log";
import { scrollTop } from "utils/helpers";

const counterSlice = createSlice({
  name: "popUp",
  initialState: {
    showPopup: false,
    showInnerPopup: false,
    addressPopup: {
      showAddressSelectionPopup: false,
      suggestedAddresses: null,
      selectedAddress: null,
    },
    feedbackPopup: {
      display: false,
      title: null,
      body: null,
      disclaimer: null,
      type: null,
      subType: null,
      buttons: null,
      hideCloseButton: false,
      noBodyText: false,
    },
    fullscreen: {
      names: [],
      isOpen: [],
    },
    inner: {
      names: [],
      isOpen: [],
    },
    closePopupTriggered: 0,
    messagePassing: null
  },
  reducers: {
    initializePopups: (state) => {
      state.fullscreen.names = [
        POPUPS.HELP,
        POPUPS.INSTRUCTIONS,
        POPUPS.CONFIRMATION_FULL,
        POPUPS.UNSUBSCRIBE,
        POPUPS.ADD_NEW_LOCATION,
        POPUPS.REJECT_OFFER,
      ];
      state.inner.names = [
        POPUPS.CONFIRMATION_INNER,
        POPUPS.DECLINE_MERCHANT,
        POPUPS.EDIT_BUSINESS,
        POPUPS.EDIT_LOCATION,
        POPUPS.EDIT_LOCATION_ASSOCIATION,
        POPUPS.EDIT_MAIN_CONTACT,
        POPUPS.EDIT_USER,
        POPUPS.EDIT_ACCOUNT_ASSOCIATION,
        POPUPS.EDIT_USER_ASSOCIATION,
        POPUPS.EDIT_USER_INFORMATION,
        POPUPS.EXPORT_REPORT,
        POPUPS.EDIT_SUPPORT,
        POPUPS.INVITATION_STATUS,
        POPUPS.INVITE_USER,
        POPUPS.LANDING_PAGE_SETTINGS,
        POPUPS.LANDING_PAGE_URL_SETTINGS,
        POPUPS.LANDING_PAGE_LOGO_SETTINGS,
        POPUPS.LOCATION,
        POPUPS.NEW_APPLICATION,
        POPUPS.NOTIFICATION,
        POPUPS.REPORT_EDIT_FIELDS,
        POPUPS.SAVE_REPORT,
        POPUPS.TABLE_FILTER,
        POPUPS.UPDATE_PASSWORD,
        POPUPS.EDIT_LENDER_GROUP,
        POPUPS.APPLICATION_HISTORY,
        POPUPS.BUSINESS_MODEL,
        POPUPS.EDIT_MERCHANT_BUSINESS_AND_MAIN_CONTACT,
        POPUPS.EDIT_PARTNER_BUSINESS_AND_MAIN_CONTACT,
        POPUPS.EDIT_WHITELABEL_BUSINESS_AND_MAIN_CONTACT,
        POPUPS.EDIT_ESTIMATED_SALES,
        POPUPS.MERCHANT_HISTORY,
        POPUPS.EDIT_LOGO,
        POPUPS.EDIT_MERCHANT_SLUG,
        POPUPS.PARTNER_HISTORY,
        POPUPS.EDIT_MERCHANT_LANDING_PAGE_TEMPLATE,
        POPUPS.EDIT_MERCHANT_LANDING_PAGE_TEMPLATE_SETTINGS,
        POPUPS.DTM_PROCESS,
        POPUPS.DTM_NOW_AVAILABLE,
        POPUPS.APPLICATION_STATUS,
        POPUPS.REQUEST_REFUND,
        POPUPS.OPTIMIZED_ADD_MERCHANT,
        POPUPS.CANCEL_LOAN,
        POPUPS.RECALCULATION,
        POPUPS.PROGRAM_VIEW,
        POPUPS.ADD_MERCHANT,
        POPUPS.ADD_PARTNER,
        POPUPS.INVITE_APPLICANT,
        POPUPS.INVITE_MERCHANT,
      ];

    },
    openPopup: (state, action: PayloadAction<{ name: string, message?: any } | string>) => {
      // first, close all open popups
      state.inner.isOpen = [...state.inner.names].map((v, i) => { return false; });
      state.fullscreen.isOpen = [...state.fullscreen.names].map((v, i) => { return false; });
      state.feedbackPopup.display = false;
      state.addressPopup.showAddressSelectionPopup = false;
      state.showInnerPopup = false;
      state.showPopup = false;

      // handle message
      let popupName = "";
      if (typeof action.payload !== typeof "") {
        popupName = (action.payload as { name: string, message?: any }).name;
        state.messagePassing = (action.payload as { name: string, message?: any }).message;
      } else {
        popupName = action.payload as string;
      }

      // open the right pop up
      if (state.inner.names.includes(popupName)) {
        state.inner.isOpen = [...state.inner.names].map((v, i) => { return i === state.inner.names.indexOf(popupName); });
        state.showInnerPopup = true;
      } else if (state.fullscreen.names.includes(popupName)) {
        state.fullscreen.isOpen = [...state.fullscreen.names].map((v, i) => { return i === state.fullscreen.names.indexOf(popupName); });
        state.showPopup = true;
      } else {
        Log.warn(`Pop up ${popupName} doesn't exist!`);
      }
      scrollTop();
    },
    closePopup: (state) => {
      state.inner.isOpen = [...state.inner.names].map((v, i) => { return false; });
      state.fullscreen.isOpen = [...state.fullscreen.names].map((v, i) => { return false; });
      state.feedbackPopup.display = false;
      state.addressPopup.showAddressSelectionPopup = false;
      state.showInnerPopup = false;
      state.showPopup = false;
      state.closePopupTriggered = state.closePopupTriggered + 1;
      state.messagePassing = null;
    },
    displayFeedbackPopup: (state, action: PayloadAction<IFeedback | null>) => {
      state.feedbackPopup.title = action.payload?.title || "Oops! Something went wrong!";
      state.feedbackPopup.body = action.payload?.body || "Something went wrong, please try again!";
      state.feedbackPopup.noBodyText = action.payload?.noBodyText;
      state.feedbackPopup.disclaimer = action.payload?.disclaimer || null;
      state.feedbackPopup.type = action.payload?.type || "UNPLUGGED";
      state.feedbackPopup.subType = action.payload?.subType || null;
      state.feedbackPopup.buttons = action.payload?.buttons || null;
      state.feedbackPopup.hideCloseButton = action.payload?.hideCloseButton || false;
      state.feedbackPopup.display = true;
      state.showInnerPopup = false;
      state.inner.isOpen = [...state.inner.names].map((v, i) => {
        if (v === action.payload?.subType) {
          return true;
        }
        return false;
      });
      state.showPopup = true;
      state.fullscreen.isOpen = [...state.fullscreen.names].map((v, i) => {
        if (v === action.payload?.subType) {
          return true;
        }
        return false;
      });
    },
    hideFeedbackPopup: (state) => {
      state.feedbackPopup.display = false;
      state.showPopup = false;
    }
  }
});

export const popUpReducer = counterSlice.reducer;
export default counterSlice.actions;